import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  /**
   *  particularly useful for S3 urls, gets the last part - used for 'key property' when working with S3
   *
   * @param {string} fullUrl
   * @returns {string} the key from the the url
   * @memberof UtilitiesService
   */
  getUrlKey(fullUrl: string): string {
    return fullUrl ? fullUrl.split('/')[fullUrl.split('/').length - 1] : null;
  }
  /**
   *  convert a JS Date into a valid nice human readable format format
   *
   * @summary particularly useful for parsing date to use with Mat datepicker
   *
   * @param {*} date
   * @returns date in string format to be used by editor
   * @memberof UtilitiesService
   */
  convertDate(date: Date): string {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    var mmChars = mm.split('');
    var ddChars = dd.split('');

    return (
      yyyy + '-' + (mmChars[1] ? mm : '0' + mmChars[0]) + '-' + (ddChars[1] ? dd : '0' + ddChars[0])
    );
  }
  /**
   *  used to validate the date being entered if its the correct format
   * Full credit to https://stackoverflow.com/questions/6177975/how-to-validate-date-with-format-mm-dd-yyyy-in-javascript for the solution
   *
   * @param {string} dateString
   * @returns {boolean}
   * @memberof UtilitiesService
   */
  isValidDate(dateString: string, splitValue: string): boolean {
    // First check for the pattern
    const regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}$/;
    if (!regex_date.test(dateString)) {
      return false;
    }

    // Parse the date parts to integers
    var parts = dateString.split(splitValue);
    var day = parseInt(parts[2], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[0], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) {
      return false;
    }

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
      monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }
  /** Elegant solution found on Stackoverflow. Credit to https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
   *  convert a bytes integer into a legible value for the front end
   *
   * @param {number} bytes
   * @param {number} [decimals=2]
   * @returns
   * @memberof UtilitiesService
   */
  formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /**
   *  counts the number of decimals in a number
   *
   * @param {number} number
   * @returns {number}
   * @memberof UtilitiesService
   */
  countDecimalPlaces(number: string | number): number {
    // Check if there are clearly no decimal places so we can returrn 0 immediately
    if (typeof number === 'number' && Math.floor(number.valueOf()) === number.valueOf()) {
      return 0;
    } else {
      // Get the number of decinals
      return number.toString().split('.')[1]?.length ?? 0;
    }
  }

  /**
   *  check if a string provided is a complete number, including negatives and floats
   *
   * @param {string} stringToCheck
   * @returns {boolean}
   * @memberof ProjectDataEditorComponent
   */
  checkForNumber(stringToCheck: string): boolean {
    const allNumbersCheck = /^-*\d[\d.,eE]*$/g;
    return allNumbersCheck.test(stringToCheck);
  }

  /**
   *  check if a string contains any special characters, matches DS check on columns
   *
   * @param {string} stringToCheck
   * @returns {boolean}
   * @memberof UtilitiesService
   */
  checkForSpecialCharacters(stringToCheck: string): boolean {
    const specialCharacters: RegExp = /[^A-Za-z0-9_."]+/g;
    return specialCharacters.test(stringToCheck);
  }

  /**
   * sort an array of objects alphabetically
   * ONLY WORKS FOR UP TO ONE NESTED OBJECT ANY FURTHER NEEDS TWEAKED
   *
   * @memberof UtilitiesService
   */
  sortArrayOfObjectsAlphabetically<dynamicType>(
    objectArray: dynamicType[],
    elementName: string
  ): dynamicType[] {
    return objectArray.sort((element1, element2) => {
      if (element1[elementName] < element2[elementName]) {
        return -1;
      }
      if (element1[elementName] > element2[elementName]) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * gets the difference between two arrays of objects - array1 compared to array 2
   *
   * @template dynamicType
   * @param {dynamicType[]} array1
   * @param {dynamicType[]} array2
   * @returns {dynamicType[]}
   * @memberof UtilitiesService
   */
  getArrayChanges<dynamicType>(
    array1: dynamicType[],
    array2: dynamicType[],
    uniqueIdentifier: string
  ): dynamicType[] {
    return array1.filter(
      (element) =>
        !array2.some((element2) => element[uniqueIdentifier] === element2[uniqueIdentifier])
    );
  }

  stringInArray(items: string[], phrase: string): boolean {
    let contained = false;
    for (const item of items) {
      if (phrase.includes(item)) {
        contained = true;
      }
    }
    return contained;
  }
}
