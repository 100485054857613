import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import L from 'leaflet';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('s3Url') s3Url: string;

  public loading = true;

  @ViewChild('viewer', {}) viewer: ElementRef;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.openLeaflet();
  }

  private openLeaflet() {
    let image = new Image();
    image.src = this.s3Url;
    image.decode().then(() => {
      let bounds = [
        [0, 0],
        [image.height, image.width],
      ];
      let minZoom =
        image.height > image.width
          ? Math.floor(image.height / this.viewer.nativeElement.clientHeight / 2) * -1
          : Math.floor(image.width / this.viewer.nativeElement.clientWidth / 2) * -1;
      let map = L.map(this.viewer.nativeElement, {
        crs: L.CRS.Simple,
        attributionControl: false,
        maxBounds: bounds,
        minZoom: minZoom,
      });
      L.imageOverlay(image, bounds).addTo(map);
      map.fitBounds(bounds);
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.s3Url = null;
  }
}
