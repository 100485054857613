import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class MaterialSnackbarService {
  constructor(private _snackBar: MatSnackBar) {}

  openSnackBar(message: string, action: string, duration: number = 5000) {
    const snackBarRef = this._snackBar.open(message, action, {
      duration: duration,
    });
  }

  // This is a seperate method with a verticalPosition property to avoid other instances of openSnackbar with no position argument breaking
  openSnackBarPosition(message: string, action: string, duration: number = 5000, verticalPosition) {
    const snackBarRef = this._snackBar.open(message, action, {
      duration: duration,
      verticalPosition: verticalPosition
    });
  }

  checkForOpenSnackbar(): boolean {
    if (this._snackBar._openedSnackBarRef) {
      return true;
    }
    return false;
  }
}
