import { Component, OnInit } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MyNotificationModel } from 'app/models/my-notification.model';
import { NotificationService } from 'app/services/api-services/notifications/notification.service';
import { UserListService } from 'app/services/aws-services/user-list.service';
import { MaterialSnackbarService } from 'app/services/shared-services/material-snackbar.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public readNotificationIds: string[] = [];
  public unreadNotificationIds: string[] = [];

  constructor(
    public notificationService: NotificationService,
    public userListService: UserListService,
    private router: Router,
    private modalService: NgbModal,
    private snackbar: MaterialSnackbarService,
  ) { }

  ngOnInit(): void {
  }

  markNotifications(markRead: boolean) {
    if (markRead) {
      this.notificationService.updateNotificationsById(this.unreadNotificationIds, true)
        .subscribe(() => {
          this.unreadNotificationIds = [];
          this.snackbar.openSnackBar('Marked as read', 'Ok');
          this.modalService.dismissAll();
        });
    } else {
      this.notificationService.updateNotificationsById(this.readNotificationIds, false)
        .subscribe(() => {
          this.readNotificationIds = [];
          this.snackbar.openSnackBar('Marked as unread', 'Ok');
          this.modalService.dismissAll();
        });
    }
  }

  deleteNotifications() {
    let deleteNoticationIds = this.readNotificationIds.concat(this.unreadNotificationIds);
    this.notificationService.deleteNotificationsById(deleteNoticationIds)
      .subscribe(() => {
        this.readNotificationIds = [];
        this.unreadNotificationIds = [];
        this.snackbar.openSnackBar('Notifications deleted', 'Ok');
        this.modalService.dismissAll();
      });
  }

  navigateByNotification(notification: MyNotificationModel) {
    if (!notification.notificationDetails.notificationRead) {
      this.notificationService.updateNotificationsById([notification.notificationId], true)
        .subscribe(() => {
          this.getNavigationRoute(notification);
        });
    } else {
      this.getNavigationRoute(notification);
    }
  }

  getNavigationRoute(notification: MyNotificationModel) {
    switch (notification.notificationDetails.notificationType) {
      case 'case_assign':
      case 'case_result':
      case 'case_update':
      case 'case_slides':
        this.router.navigateByUrl(`/cases/${notification.notificationDetails.notificationSourceId}`)
        break;
    }
    this.modalService.dismissAll();
  }

  getNotificationText(notification: MyNotificationModel): string {
    let returnText: string;
    switch (notification.notificationDetails.notificationType) {
      case 'case_assign':
        returnText = `Assigned to ${notification.notificationDetails.notificationSourceName} by ${this.userListService.getUserFullNameById(notification.notificationDetails.ownerId)}`
        break;
      case 'case_result':
        returnText = `A result has been returned for ${notification.notificationDetails.notificationSourceName}`
        break;
      case 'case_unassign':
        returnText = `Unassigned from ${notification.notificationDetails.notificationSourceName} by ${this.userListService.getUserFullNameById(notification.notificationDetails.ownerId)}`
        break;
      case 'case_update':
        returnText = `Case ${notification.notificationDetails.notificationSourceName} was updated by ${this.userListService.getUserFullNameById(notification.notificationDetails.ownerId)}`
        break;
      case 'case_slides':
        returnText = `${this.userListService.getUserFullNameById(notification.notificationDetails.ownerId)} added slides to case ${notification.notificationDetails.notificationSourceName}`
        break;
    }
    return returnText;
  }

  notificationChecked(checked: boolean, notification: MyNotificationModel) {
    if (!checked) {
      if (notification.notificationDetails.notificationRead) {
        this.readNotificationIds.push(notification.notificationDetails.notificationId)
      } else {
        this.unreadNotificationIds.push(notification.notificationDetails.notificationId)
      }
    } else if (this.readNotificationIds.includes(notification.notificationDetails.notificationId)) {
      this.readNotificationIds.splice(this.readNotificationIds.indexOf(notification.notificationDetails.notificationId), 1);
    } else {
      this.unreadNotificationIds.splice(this.unreadNotificationIds.indexOf(notification.notificationDetails.notificationId), 1);
    }
  }

}
