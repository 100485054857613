<div class="notifications-menu">
  <div
    *ngIf="
      readNotificationIds.length > 0 || unreadNotificationIds.length > 0
    "
    class="notifications-actions"
  >
    <button mat-icon-button class="btn pull-right">
      <mat-icon
        (click)="markNotifications(true)"
        *ngIf="unreadNotificationIds.length > 0 && readNotificationIds.length === 0"
        color="primary"
        matTooltip="Mark Notifications as Read"
        class="notifications-icon"
      >mark_email_read</mat-icon>
      <mat-icon
        (click)="markNotifications(false)"
        *ngIf="readNotificationIds.length > 0 && unreadNotificationIds.length === 0"
        matTooltip="Mark Notifications as Unread"
        color="primary"
        class="notifications-icon text-warning"
      >mark_email_unread</mat-icon>
      <mat-icon
        *ngIf="
          readNotificationIds.length > 0 || unreadNotificationIds.length > 0
        "
        (click)="deleteNotifications()"
        matTooltip="Delete Notification"
        class="project-icon-lg d-block text-danger tooltip-z"
      >delete_forever</mat-icon>
    </button>
  </div>
</div>

<div class="modal-body">
  <div class="scroll-area-lg col-vh-height">
    <perfect-scrollbar
      #scrollbar
      [autoPropagation]="true"
    >
      <li *ngFor="let notification of notificationService.myNotifications" class="list-group-item">
        <div class="widget-content p-0">
          <div class="widget-content-wrapper">
            <div class="widget-content-left mr-2">
              <div
                class="avatar-circle"
                mat-raised-button
                matTooltipPosition="right"
                matTooltip="{{ userListService.getUserFullNameById(notification.notificationDetails.ownerId) }}"
              >
                <ngx-avatars
                  name="avatar"
                  [name]="userListService.getUserFullNameById(notification.notificationDetails.ownerId)"
                  [size]="40"
                  [round]="true"
                  [initialsSize]="3"
                  [src]="userListService.getUserByUserId(notification.notificationDetails.ownerId).pictureBase64?.toString()"
                  [bgColor]="userListService.getUserByUserId(notification.notificationDetails.ownerId).favouriteColour || '#2DD070'"
                >
                </ngx-avatars>
              </div>
            </div>
            <div class="widget-content-left flex2">
              <div
                class="title-name note-title read-notification"
                [ngClass]="{
                  'widget-heading':
                    !notification.notificationDetails.notificationRead
                }"
              >
                <div (click)="navigateByNotification(notification)">
                  {{ getNotificationText(notification) }}
                </div>
              </div>
              <div class="widget-subheading pull-right"></div>
              <div class="widget-subheading">
                {{ notification.notificationDetails.created | date: 'medium' }}
              </div>
            </div>
            <div class="widget-content-right mr-2">
              <div class="custom-checkbox custom-control">
                <mat-checkbox
                  #checkbox
                  color="primary"
                  class="example-margin"
                  (click)="notificationChecked(checkbox.checked, notification)"
                ></mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </li>
    </perfect-scrollbar>
  </div>
</div>
<div class="modal-footer"></div>
