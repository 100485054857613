import { Injectable, OnDestroy } from '@angular/core';
import { ProjectUsersModel } from '@app/models/project-users.model';
import { RolePermissionDetailsModel } from '@app/models/role-permission-details.model';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { ProjectUserService } from '../api-services/project-users/project-user.service';
import { ProjectService } from '../api-services/projects/project.service';
import { ProjectModel } from '@app/models/project.model';
import { SubSink } from '@base/node_modules/subsink/dist/subsink';
import { switchMap } from 'rxjs/operators';
import { RolePermissionDetailsService } from '../api-services/role-permissions-details/role-permission-details.service';
import { OrganisationCognitoUser } from '@app/models/cognito-organisation-user.model';
import { MaterialSnackbarService } from './material-snackbar.service';
import { ProjectUsersListService } from '../api-services/project-users/project-users-list.service';
import { ApiService } from '../api-services/api.service';

export class DetailedProjectUserModel {
  user?: OrganisationCognitoUser;
  projectUser?: ProjectUsersModel;
  rolePermissions?: string[];
  projectSlug?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ProjectUserPermissionsService implements OnDestroy {
  private previousUserDetails: DetailedProjectUserModel;
  private projectSubcription: Subscription;
  private multipleProjectsSubscription: Subscription;
  // Subsink to be used in the OnDestroy Lifecycle hook to destroy all active subscriptions
  private subscriptionContainer = new SubSink();

  constructor(
    private projectUserService: ProjectUserService,
    private projectService: ProjectService,
    private rolePermissionDetailsService: RolePermissionDetailsService,
    private notify: MaterialSnackbarService,
    private projectUserListService: ProjectUsersListService,
    private apiService: ApiService
  ) {}

  getUserPermissions(
    projectSlug: string,
    user: OrganisationCognitoUser
  ): Observable<DetailedProjectUserModel> {
    return new Observable<DetailedProjectUserModel>((observer) => {
      let project: ProjectModel;
      let projectUser: ProjectUsersModel;
      const currentUser = new DetailedProjectUserModel();
      if (
        user.userId === this.previousUserDetails?.projectUser?.userId &&
        projectSlug === this.previousUserDetails?.projectSlug &&
        this.rolePermissionDetailsService.rolePermissionDetailList.length
      ) {
        this.rolePermissionDetailsService.getRolePermissionDetailsByRoleId(this.previousUserDetails.projectUser.roleId).subscribe(res => {
          this.previousUserDetails.rolePermissions = res.map((perm) => perm.permissionDetails.permissionName);
        })
      } else {
        currentUser.user = user;
        this.subscriptionContainer.sink = this.projectSubcription = this.projectService.dataSubject
          .pipe(
            switchMap((projects) => {
              for (const proj of projects) {
                if (proj instanceof ProjectModel) {
                  project = proj;
                } else {
                  project = new ProjectModel();
                  project = project.fromJson(proj);
                }
              }
              currentUser.projectSlug = project.projectSlug;
              this.projectUserService.getProjectUserByIdAndUserId(project.projectId, user.userId);
              return this.projectUserService.dataSubject;
            }),
            switchMap((projectUsers) => {
              for (const proj of projectUsers) {
                if (proj instanceof ProjectUsersModel) {
                  projectUser = proj;
                } else {
                  projectUser = new ProjectUsersModel();
                  projectUser = projectUser.fromJson(proj);
                }
              }
              currentUser.projectUser = projectUser;
              // Uses the new API service, fixes a pre existing bug caused by really annoying subject issues and concurrency of requests
              // Will never use the cache to ideally keep this as up to date as possible
              return this.apiService.getEndpoint<RolePermissionDetailsModel>(
                'role_permissions_details',
                `role_id=eq.${projectUser.roleId}`
              );
            })
          )
          .subscribe(
            (foundPermissions) => {
              currentUser.rolePermissions = foundPermissions.map(
                (perm) => perm.permissionDetails.permissionName
              );
              if (this.projectSubcription) {
                this.projectSubcription.unsubscribe();
              }
              this.previousUserDetails = currentUser;
              observer.next(currentUser);
              observer.complete();
            },
            (err) => {
              currentUser.rolePermissions = [];
              observer.next(currentUser);
              observer.complete();
              if (this.projectSubcription) {
                this.projectSubcription.unsubscribe();
              }
              this.notify.openSnackBar(
                `Failed to initialise user permissions - please retry - ${err}`,
                'Close'
              );
            }
          );
        this.projectService.getProjectFromListByProjectSlug(projectSlug);
      }
    });
  }

  /**
   * Gets the detailed permission breakdown for a user across multiple projects-list - relied on by projects-list component
   *
   * @param {ProjectModel[]} projects
   * @param {OrganisationCognitoUser} user
   * @returns {Observable<DetailedProjectUserModel[]>}
   * @memberof ProjectUserPermissionsService
   */
  getMultipleUserDetails(
    projects: ProjectModel[],
    user: OrganisationCognitoUser
  ): Observable<DetailedProjectUserModel[]> {
    return new Observable<DetailedProjectUserModel[]>((observer) => {
      let rolePermissionDetail: RolePermissionDetailsModel;
      let rolePermissionDetailsList: RolePermissionDetailsModel[];
      let projectUser: ProjectUsersModel;
      let projectUsers: ProjectUsersModel[];
      let returnUsers: DetailedProjectUserModel[];
      let returnUser: DetailedProjectUserModel;
      this.subscriptionContainer.sink = this.multipleProjectsSubscription = combineLatest([
        this.rolePermissionDetailsService.rolePermissionDetailList,
        this.projectUserListService.dataSubject,
      ]).subscribe(([rolePermissions, foundUsers]) => {
        rolePermissionDetailsList = [];
        projectUsers = [];
        returnUsers = [];
        for (const proj of foundUsers) {
          if (proj instanceof ProjectUsersModel) {
            projectUser = proj;
          } else {
            projectUser = new ProjectUsersModel();
            projectUser = projectUser.fromJson(proj);
          }
          projectUsers.push(projectUser);
        }
        for (const perm of Object.keys(rolePermissions)) {
          // @ts-ignore
          if (perm instanceof RolePermissionDetailsModel) {
            rolePermissionDetail = perm;
          } else {
            rolePermissionDetail = new RolePermissionDetailsModel();
            rolePermissionDetail = rolePermissionDetail.fromJson(perm);
          }
          rolePermissionDetailsList.push(rolePermissionDetail);
        }
        for (const project of projects) {
          returnUser = new DetailedProjectUserModel();
          returnUser.projectSlug = project.projectSlug;
          returnUser.user = user;
          returnUser.projectUser = projectUsers.find(
            (findUser) =>
              findUser.userId === user.userId && project.projectId === findUser.projectId
          );
          returnUser.rolePermissions = rolePermissionDetailsList
            .filter((rolePerm) => rolePerm.roleId === returnUser.projectUser?.roleId)
            .map((rolePerm) => rolePerm.permissionDetails.permissionName);
          returnUsers.push(returnUser);
        }
        observer.next(returnUsers);
        if (this.multipleProjectsSubscription) {
          this.multipleProjectsSubscription.unsubscribe();
        }
      });
      this.projectUserListService.getData({});
    });
  }

  ngOnDestroy() {
    this.subscriptionContainer.unsubscribe();
  }
}
