import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { animate, query, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { filter, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { TokenRefreshService } from '@app/services/aws-services/token-refresh.service';
import { OrganisationCognitoUser } from '@app/models/cognito-organisation-user.model';
import { OrganisationUsersModel } from '@app/models/organisation-users.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OfflineComponent } from 'app/offline/offline.component';
import { MaterialSnackbarService } from '@app/services/shared-services/material-snackbar.service';
import { LoginModalComponent } from '@app/components/login-modal/login-modal.component';
import { OrganisationUsersListService } from '@app/services/api-services/organisation-users/organisation-users-list.service';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { HttpCacheService } from 'app/services/http/http-cache.service';
import { ConnectionService } from 'app/services/shared-services/connection.service';
import { UserListService } from 'app/services/aws-services/user-list.service';
import { MyDetailedNotificationsService } from '@app/services/shared-services/my-detailed-notifications.service';
import { NotificationService } from '@app/services/api-services/notifications/notification.service';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
  animations: [
    trigger('architectUIAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: 0,
            display: 'flex',
            flex: '1',
            transform: 'translateY(-20px)',
            flexDirection: 'column',
          }),
        ]),
        query(':enter', [animate('600ms ease', style({ opacity: 1, transform: 'translateY(0)' }))]),
      ]),
    ]),
  ],
})
export class BaseLayoutComponent implements OnInit {
  breadcrumbConfig: object = {
    bgColor: 'none',
    fontColor: '#3E455F',
    lastLinkColor: '#2DD070',
    symbol: ' » ',
  };
  private apiFetchExpiryTime = new Date().getTime() + 300000;
  public currentUserId: string;
  onlineStatus = true;
  backOnlineUpdate: boolean;
  // Subscriptions
  private userSubscription: Subscription;
  expandSubmenu: boolean;
  user: OrganisationCognitoUser;
  cognitoUserList: OrganisationCognitoUser[];
  isModalDataSet: boolean = false;
  userBoxClass: string = '';
  userBoxDropDownClass: string = 'header-dropdown-menu-userbox';
  newNotificationsCount: number;
  isShow: boolean = false;

  /**
   * Creates an instance of BaseLayoutComponent.
   *
   * @constructor
   * @param {ActivatedRoute} act
   * @param {AuthenticationService} authenticationService
   * @param {TokenRefreshService} tokenRefreshService
   * @param {OrganisationUsersListService} orgUserService
   * @param {NgbModal} modalService
   * @param {MaterialSnackbarService} notify
   * @param {HttpCacheService} cacheService
   * @param {UserListService} userListService
   * @param {ConnectionService} connectionService
   * @param {MyDetailedNotificationsService} myDetailedNotificationsService
   * @param {NotificationService} notificationService
   */
  constructor(
    public act: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private tokenRefreshService: TokenRefreshService,
    private orgUserService: OrganisationUsersListService,
    private modalService: NgbModal,
    private notify: MaterialSnackbarService,
    private cacheService: HttpCacheService,
    private userListService: UserListService,
    private connectionService: ConnectionService,
    private myDetailedNotificationsService: MyDetailedNotificationsService,
    public notificationService: NotificationService
  ) {}

  /**
   * TODO Description placeholder
   */
  ngOnInit() {
    this.user = this.authenticationService.user;
    this.tokenRefreshService.checkSessionIsActive();
    this.connectionService.monitor().subscribe((value) => {
      if (value && !this.onlineStatus) {
        this.modalService.dismissAll();
        this.backOnlineUpdate = true;
        setTimeout(() => {
          this.backOnlineUpdate = false;
        }, 5000);
      } else if (!value) {
        this.modalService.dismissAll();
        this.modalService.open(OfflineComponent, {
          backdrop: 'static',
          backdropClass: 'login-backdrop',
          windowClass: 'login-window',
          keyboard: false,
        });
      }
      this.onlineStatus = value;
    });
    this.tokenRefreshService.refreshSession();
    let orgUser: OrganisationUsersModel;
    let currentUser: OrganisationUsersModel;
    let modalRef: NgbModalRef;
    this.userSubscription = this.authenticationService.user$
      .pipe(
        // User subject required due to Base layout only instantiated once and the MFA/New password pages passing in a null user
        filter((user) => user !== null),
        distinctUntilChanged(),
        switchMap((user: OrganisationCognitoUser) => {
          // IMPORTANT - when the base layout is initialised - clear all the lists and counts for the notifications
          // This means that for each login a fresh list is displayed for the new user
          // this.myDetailedNotificationsService.currentMyNotificationsList = [];
          // this.myDetailedNotificationsService.passUpdatedNewList([]);
          // this.myDetailedNotificationsService.passUpdatedList([]);
          // this.myDetailedNotificationsService.passNewCount(0);
          // this.myDetailedNotificationsService.displayLimit = 15;
          this.myDetailedNotificationsService.userList = this.act.snapshot.data.userList;
          // Get the new notifications
          this.currentUserId = user.userId;
          this.userListService.getUserListFromLocalList();
          return this.userListService.dataSubject;
        })
      )
      .subscribe((data) => {
        this.cognitoUserList = data;
        this.myDetailedNotificationsService.userList = data;
        this.notificationService.getMyNotificationsByUserId(this.user.userId)
          .subscribe((notifications) => {
            this.newNotificationsCount = notifications.length;
          });
        for (const dataUser of data) {
          if (dataUser instanceof OrganisationUsersModel) {
            orgUser = dataUser;
          } else {
            orgUser = new OrganisationUsersModel();
            orgUser = orgUser.fromJson(dataUser);
          }
          if (orgUser.userId === this.currentUserId) {
            currentUser = orgUser;
          }
        }
        if (currentUser?.sessionLocked) {
          this.modalService.dismissAll();
          modalRef = this.modalService.open(LoginModalComponent, {
            backdrop: 'static',
            backdropClass: 'login-backdrop',
            windowClass: 'login-window',
          });
          modalRef.componentInstance.username = this.authenticationService.user.username;
          this.notify.openSnackBar(
            'You have been locked out due to inactivity. Please log back in to ensure your session data is not lost.',
            null
          );
        }
        if (this.userSubscription) {
          this.userSubscription.unsubscribe();
        }
      });
    setInterval(() => {
      this.cacheService.cleanCache(this.apiFetchExpiryTime);
      this.apiFetchExpiryTime = this.apiFetchExpiryTime + 300000;
    }, 300000);
  }

  /**
   * TODO Description placeholder
   *
   * @param {*} expandSubmenu
   */
  setSubmenu(expandSubmenu) {
    this.expandSubmenu = expandSubmenu;
  }

  /**
   * TODO Description placeholder
   *
   * @param {TemplateRef<any>} content
   * @param {string} size
   * @returns {*}
   */
  openLargeModal(content: TemplateRef<any>, size: string): any {
    this.modalService.open(content, {
      size: size,
      scrollable: true,
      windowClass: 'z-index-modal',
      backdrop: true,
      backdropClass: 'z-index-backdrop',
    });
  }

  /**
   * TODO Description placeholder
   */
  openDisplay() {
    // Check if isShow is true or false in order to toggle display on click
    if (this.isShow === false) {
      setTimeout(() => {
        this.isShow = true;
      }, 0);
    } else {
      this.isShow = false;
    }
  }

  /**
   * TODO Description placeholder
   *
   * @param {*} event
   */
  closeWindow(event) {
    if (this.isShow) {
      event.stopPropagation();
      this.isShow = false;
    }
  }

  /**
   * TODO Description placeholder
   */
  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
