import { Directive, HostListener, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MaterialSnackbarService } from '@app/services/shared-services/material-snackbar.service';
import { Subject, Subscription } from 'rxjs';
import { LoginModalComponent } from '@app/components/login-modal/login-modal.component';
import { OrganisationUserService } from '@app/services/api-services/organisation-users/organisation-user.service';
import { OrganisationUsersListService } from '@app/services/api-services/organisation-users/organisation-users-list.service';
import { OrganisationUsersModel } from '@app/models/organisation-users.model';
import { AuthenticationService } from '@app/services/authentication/authentication.service';

@Directive({
  selector: '[appActivityTracker]',
})
export class ActivityTrackerDirective implements OnDestroy {
  userActivity: NodeJS.Timeout;
  userInactive: Subject<NodeJS.Timeout> = new Subject();
  modalRef: NgbModalRef;

  // Subscriptions
  updateLockSubscription: Subscription;
  inactivitySubscription: Subscription;
  constructor(
    private auth: AuthenticationService,
    private notify: MaterialSnackbarService,
    private modalService: NgbModal,
    private organisationUserService: OrganisationUserService,
    private organisationUsersListService: OrganisationUsersListService
  ) {
    let user: OrganisationUsersModel;
    this.setTimeout();
    this.inactivitySubscription = this.userInactive.subscribe(() => {
      user =
        this.organisationUsersListService.getOrganisationUserByUserId(this.auth.user?.userId) ??
        null;
      if (!user?.sessionLocked) {
        this.updateLockSubscription = this.organisationUserService.updateOrganisationUserSessionLock(
          this.auth.user?.userId,
          true
        ).subscribe(() => {
          this.modalService.dismissAll();
          this.modalRef = this.modalService.open(LoginModalComponent, {
            backdrop: 'static',
            backdropClass: 'login-backdrop',
            windowClass: 'login-window',
            keyboard: false,
          });
          this.modalRef.componentInstance.username = this.auth.user.username;
          this.notify.openSnackBar(
            'You have been locked out due to inactivity. Please log back in to ensure your session data is not lost.',
            null
          );
          if (this.updateLockSubscription) {
            this.updateLockSubscription.unsubscribe();
          }
        });
      }
    });
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 3600000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  ngOnDestroy() {
    if (this.updateLockSubscription) {
      this.updateLockSubscription.unsubscribe();
    }
    if (this.inactivitySubscription) {
      this.inactivitySubscription.unsubscribe();
    }
  }
}
