import { Injectable, Injector } from '@angular/core';
import { environment } from '@env/environment';
import * as AWS from 'aws-sdk';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ParameterStoreService {
  constructor(private auth: AuthenticationService, private injector: Injector) {}

  getParameter(name: string): Observable<any> {
    const ssm = new AWS.SSM({
      region: environment.cognitoSettings.region,
      credentials: this.auth.accessCredentials,
    });
    return new Observable<any>((observer) => {
      ssm.getParameter(
        {
          Name: name,
          WithDecryption: false,
        },
        (err, data) => {
          if (err) {
            observer.next(err);
          } else {
            observer.next(data);
          }
        }
      );
    });
  }
}
