<div class="software-form-container">
  <div class="form-group">
    <labeL>
      Title: Sonrai Diagnostics Platform UDI
    </labeL>
  </div>
  <div class="form-group">
    <labeL>
      Document Ref: S002-019-002
    </labeL>
  </div>
  <div class="form-group">
    <labeL>
      Revision: 0
    </labeL>
  </div>
  <!-- Barcode Image -->
  <div class="form-group">
    <img src="../../../../assets/images/barcode.png" alt="barcode" />
  </div>
  <!-- Table -->
  <div class="form-group">
    <table class="table">
      <tr>
        <th>Revision</th>
        <th>Change Reference</th>
      </tr>
      <tr>
        <td>0</td>
        <td>Initial Release</td>
      </tr>
    </table>
  </div>
</div>
