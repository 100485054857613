import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationUsersModel } from '@app/models/organisation-users.model';
import { OrganisationUserService } from '@app/services/api-services/organisation-users/organisation-user.service';
import { CognitoAdminUtilsService } from '@app/services/aws-services/cognito-admin-utils.service';
import { MaterialSnackbarService } from '@app/services/shared-services/material-snackbar.service';
import { Subscription } from 'rxjs';
import { SubSink } from 'subsink/dist/subsink';
import { AuthenticationService } from '@app/services/authentication/authentication.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnDestroy {
  @Input() username: string;

  password = '';
  code = '';
  user: OrganisationUsersModel;
  loading: boolean;

  private userSubscription: Subscription;
  private organisationUserSubscription: Subscription;
  // Subsink to be used in the OnDestroy Lifecycle hook to destroy all active subscriptions
  private subscriptionContainer = new SubSink();
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private notify: MaterialSnackbarService,
    private modalService: NgbModal,
    private adminUtils: CognitoAdminUtilsService,
    private organisationUserService: OrganisationUserService
  ) {}

  /**
   *  Ryan Duffy
   *
   *  login
   *
   *  login a user based on the credentials they have entered using AWS cognito
   *
   *  @param None
   *
   *  @returns void: user login
   */
  unlockSession() {
    this.loading = true;
    this.subscriptionContainer.sink = this.userSubscription = this.adminUtils
      .adminValidateCredentials(this.username, this.password)
      .subscribe(
        () => {
          if (this.authService.user) {
            this.subscriptionContainer.sink = this.organisationUserSubscription = this.organisationUserService.updateOrganisationUserSessionLock(
              this.authService.user.userId,
              false
            ).subscribe(
              () => {
                this.loading = false;
                this.modalService.dismissAll();
                this.notify.openSnackBar('Successfully Unlocked Session', 'Close');
                if (this.organisationUserSubscription) {
                  this.organisationUserSubscription.unsubscribe();
                }
              }
            );
          }
        },
        (err) => {
          this.password = '';
          this.loading = false;
          this.notify.openSnackBar(err.message, 'Close');
          if (this.userSubscription) {
            this.userSubscription.unsubscribe();
          }
        }
      );
  }

  backToLogin() {
    this.authService.logout().subscribe(() => {
      this.modalService.dismissAll();
      this.router.navigateByUrl('/login');
    });
  }

  ngOnDestroy(): void {
    this.subscriptionContainer.unsubscribe();
  }
}
