import { Injectable } from '@angular/core';
import { SettingsModel } from '@app/models/settings.model';
import { Observable, Subscription } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { SubSink } from 'subsink';
import { GET_SETTINGS, UPDATE_SETTING } from './settings-queries';
@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  settings: SettingsModel;

  private settingsSubscription: Subscription;
  private subscriptionContainer: SubSink = new SubSink();

  constructor(
    private apollo: Apollo
  ) { }

  getSettings(organisationId: string): Observable<SettingsModel> {
    if (!!this.settings && !!this.settings.organisationId) {
      return new Observable(subscriber => {
        subscriber.next(this.settings);
      });
    } else {
      return new Observable<SettingsModel>((subscriber) => {
        this.subscriptionContainer.sink = this.settingsSubscription = this.apollo.query({
          query: GET_SETTINGS,
          variables: {
            condition: {
              organisationId: organisationId
            }
          }
        }).subscribe((result: any) => {
          this.settings = Object.assign(new SettingsModel, result.data.settings[0]);
          sessionStorage.setItem('settings', JSON.stringify(this.settings));
          localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
          subscriber.next(this.settings);
          if (this.settingsSubscription) {
            this.settingsSubscription.unsubscribe();
          }
        },
        (error) => {
          if (this.settingsSubscription) {
            this.settingsSubscription.unsubscribe();
          }
        });
      });
    }
  }

  setSettings(settings: SettingsModel) {
    this.settings = settings;
  }

  public getSettingsModel(): SettingsModel {
    return this.settings;
  }

  public updateDisplaySettings(organisationId: string, data): Observable<String> {
    return new Observable(subscriber => {
      this.apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            patch: data,
            organisationId: organisationId
          }
        }
      }).subscribe(res => {
        subscriber.next();
        subscriber.complete();
      });
    });
  }

}