import { gql } from 'apollo-angular';

export const GET_ORGANISATION_USERS_BY_ORG_ID = gql`
  query getOrganisationUsersByOrganisationId($condition: OrganisationUserCondition!) {
    organisationUsers(condition: $condition) {
      createdAt
      organisationId
      roleId
      organisationUserStatus
      userId
      updatedAt
      sonraiSuperUser
      sessionTimestamp
      sessionLocked
      sessionId
      runAnalytics
    }
  }
`

export const UPDATE_ORGANISATION_USER = gql`
  mutation updateOrganisationUser($input: UpdateOrganisationUserInput!) {
    updateOrganisationUser(input: $input) {
      createdAt
      organisationId
      organisationUserStatus
      roleId
      runAnalytics
      sessionId
      sessionLocked
      sessionTimestamp
      sonraiSuperUser
      updatedAt
      userId
    }
  }
`

export const CREATE_ORGANISATION_USER = gql`
  mutation createOrganisationUser($input: CreateOrganisationUserInput!) {
    createOrganisationUser(input: $input) {
      organisationId
      organisationUserStatus
      createdAt
      updatedAt
      roleId
      sessionId
      sessionTimestamp
      sessionLocked
      runAnalytics
      sonraiSuperUser
      userId
    }

  }
`

export const DELETE_ORGANISATION_USER = gql`
  mutation deleteOrganisationUser($input: DeleteOrganisationUserInput!) {
    deleteOrganisationUser(input: $input) {
      createdAt
      organisationId
      organisationUserStatus
      roleId
      runAnalytics
      sessionId
      sessionLocked
      sessionTimestamp
      sonraiSuperUser
      updatedAt
      userId
    }
  }
`

