import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { SettingsService } from '@app/services/api-services/settings/settings.service';
@Injectable({
  providedIn: 'root',
})
export class CaseDetailsGuard {
  settings = this.settingsService.getSettingsModel();
  permissions: string[] = [];
  constructor(
      private router: Router,
      private auth: AuthenticationService,
      private settingsService: SettingsService
    ) {}

  canActivate() {
    if (this.permissions.length === 0) {
      this.auth.permissions.forEach((perm) => {
        this.permissions.push(perm.componentActionKey);
      });
    }
    if (this.permissions.length > 0) {
      if (this.settings.displayCases && this.permissions.includes('view_cases_screen')) {
        return true;
      } else {
        this.router.navigateByUrl('/cases');
        return false;
      };
    }
  }

}