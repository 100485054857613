import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { each } from 'lodash-es';

export interface HttpCacheEntry {
  lastUpdated: Date;
  data: HttpResponse<any>;
}

/**
 * Provides a cache facility for HTTP requests with configurable persistence policy.
 */
@Injectable({ providedIn: 'root' })
export class HttpCacheService {
  cachedData: { [key: string]: HttpCacheEntry } = {};
  constructor() {}

  /**
   *
   *  setCacheData
   *
   *  Sets the cache data for the specified request.
   *
   * @param url The request URL.
   * @param data The received data.
   * @param lastUpdated The cache last update, current date is used if not specified.
   *
   * @return void: sets cache data
   */
  setCacheData(url: string, data: HttpResponse<any>, lastUpdated?: Date) {
    this.cachedData[url] = {
      lastUpdated: lastUpdated || new Date(),
      data: data,
    };
  }
  /**
   *
   * @param endpoint
   * @param action
   * @param data
   * @param idColumn1
   * @param idColumn2
   */
  updateCacheItem(
    endpoint: string,
    action: string,
    data: Object,
    idColumn1?: string,
    idColumn2?: string
  ) {
    let i: number;
    let url: string;
    let foundStore: any;
    Object.keys(this.cachedData).forEach((key) => {
      if (key.includes(`/${endpoint}`)) {
        url = key;
        foundStore = this.cachedData[url].data.body;
        switch (action) {
          case 'add':
            foundStore.push(data);
            break;
          case 'patch':
            i = 0;
            for (const item of foundStore) {
              if (
                item[idColumn1] === data[idColumn1] &&
                (!idColumn2 || item[idColumn2] === data[idColumn2])
              ) {
                foundStore.splice(i, 1);
                foundStore.push(data);
              }
              i++;
            }
            break;
          case 'delete':
            i = 0;
            for (const item of foundStore) {
              if (
                item[idColumn1] === data[idColumn1] &&
                (!idColumn2 || item[idColumn2] === data[idColumn2])
              ) {
                foundStore.splice(i, 1);
              }
              i++;
            }
            break;
        }
      }
    });
  }

  /**
   *
   *  getCacheData
   *
   * : Gets the cache data for the specified request.
   *
   * @param url The request URL.
   *
   * @return The cached data or null if no cached data exists for this request.
   */
  getCacheData(url: string): HttpResponse<any> | null {
    const cacheEntry = this.cachedData[url];
    if (cacheEntry) {
      return cacheEntry.data;
    }
    return null;
  }

  /**
   *
   *  clearCache
   *
   * : Clears the cached entry (if exists) for the specified request.
   *
   * @param url The request URL.
   *
   * @return The cached data or null if no cached data exists for this request.
   */
  /**
   * Clears the cached entry (if exists) for the specified request.
   * @param url The request URL.
   */
  clearCache(url: string): void {
    delete this.cachedData[url];
  }
  /**
   *
   *  cleanCache
   *
   * : Cleans cache entries older than the specified date.
   *
   * @param expirationDate The cache expiration date. If no date is specified, all cache is cleared.
   *
   * @returns void
   */
  cleanCache(expirationDate?: number) {
    if (expirationDate) {
      each(this.cachedData, (value: HttpCacheEntry, key: string) => {
        if (expirationDate > value.lastUpdated.getTime()) {
          delete this.cachedData[key];
        }
      });
    } else {
      this.cachedData = {};
    }
  }
}
