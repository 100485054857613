
 <div class="scroll-area-lg col-vh-height">
  <perfect-scrollbar [autoPropagation]="true">
    <div class="container mb-4" appDnd>
      <div class="transformProgressDiv">
        <mat-accordion class="mb-4">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                What format does my data need to be in?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              Applications within InDRA utilize a data format known as ‘tidy data’. Tidy
              data is a standard way of mapping the meaning of a dataset to its
              structure.
            </p>
            <ul>
              <li>Every column is a feature. (e.g. Gene Symbol)</li>
              <li>Every row is an observation. (e.g. Patient)</li>
              <li>Every cell is a single value. (e.g. Numeric)</li>
            </ul>
            <img
              class="img-faq mb-1"
              src="https://indra-assets.s3.us-east-2.amazonaws.com/user-guides/tidy.png"
            />
            <p>
              Sonraí can provide hands-on data science & engineering support to help
              format & automated your data ingestion & integration.
            </p>
            <p>
              You can read more about the
              <a
                target="_blank"
                class="text-link"
                href="https://indra-assets.s3.us-east-2.amazonaws.com/user-guides/Indra-app-data.pdf"
                >InDRA App Data format here.</a
              >
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>How can I use InDRA to integrate data?</mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              The integration of clinical meta data with matched molecular profiles is
              an essential part of any bioinformatics workflow. Data integration can be
              very challenging for non-technical users. Our data integration engine
              streamlines this process by scanning your sample data files for a common
              index. The common index InDRA uses for merging is unique_id. This must be
              present for merging to take place. The unique_id indexes must match
              between your two files and the name of the feature must be unique_id in
              lower_case.
            </p>
            <ul>
              <li>Clinical Metadata stored in a tidy format.</li>
              <li>Molecular or high-dimensional data stored in a long format.</li>
              <li>
                Our Integration Engine will identify these files and perform the merge
                automatically.
              </li>
            </ul>
            <p>
              <a
                target="_blank"
                class="text-link text-center"
                href="https://indra-assets.s3.us-east-2.amazonaws.com/user-guides/integrate-data.pdf"
                >Click here to follow our easy 3 step guide.</a
              >
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>How do I use an Analytics Application?</mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              Data-Science Analytics come with built in help information, images or
              video guides. If you click the <mat-icon>help_outline</mat-icon> icon you
              will see the following:
            </p>
            <ul>
              <li>
                <b>App Type</b> - what it tends to work best with (e.g. numerical or
                categorical data)
              </li>
              <li><b>Description</b> - how the App works and important features</li>
              <li>
                <b>Use Case</b> - provides relevant example of where the App may be
                suited for a particular task
              </li>
            </ul>
            <p>
              There are also various help screens built into the platform, and some
              advanced applications may require Data Science, Bioinformatics or
              Analytical experience. The Sonraí Support team are happy to work with you,
              answer any queries or offer advice & training with using a particular
              approach.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>Cookies & Analytics</mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              InDRA provides a secure Trusted Research Environment. All data belongs to
              your organisation and nothing is shared with third parties. Some features
              of the platform will require you to enable Cookies in your browser in
              order to function correctly.
            </p>
            <ul>
              <li>
                Cookies are used to authenticate your session & to help protect your
                platform
              </li>
              <li>
                Blocking InDRA cookies could result in certain features failing or
                security checks rejecting you from loading Apps or accessing data
              </li>
            </ul>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>General help and support</mat-panel-title>
            </mat-expansion-panel-header>
            <ul>
              <li>
                Each major screen (Projects, Analytics, Datasets etc...) contains built
                in Tutorials & Training which can be activated at any time by clicking
                the <mat-icon>help_outline</mat-icon> icon.
              </li>
              <li>
                Tutorials will interactively explain what a button or feature does, and
                encourage Users to follow a step-by-step guide to perform a particular
                task.
              </li>
              <li>
                The Analytics & Storyboards screen also provides detailed information
                and video guides on individual applications and how to correctly use
                them to generate a visualization or result.
              </li>
            </ul>
            <p>
              The Sonraí Support team are also on hand to provide additional training or
              support to help Users learn to navigate the system or to complete a
              particular task or goal.
            </p>
          </mat-expansion-panel>
        </mat-accordion>
        <br/>
        <h6 class="bold text-center">
          Contact Sonraí Support for help and advice
          <a class="text-link" href="mailto:support@sonraianalytics.com"
            >support@sonraianalytics.com</a
          >
        </h6>
      </div>
    </div>

    <!-- <ul class="todo-list-wrapper list-group list-group-flush">
      <li class="list-group-item">
        <div class="todo-indicator bg-primary"></div>
        <div class="widget-content p-0">
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-heading">
                <a [routerLink]="['/user-guides']"> User Guides</a>
              </div>
              <div class="widget-subheading">
                <i>homeTraining guides for the platform and associated applications </i>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class="todo-indicator bg-primary"></div>
        <div class="widget-content p-0">
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-heading">
                <a [routerLink]="['/contact-us']"> Contact Us</a>
              </div>
              <div class="widget-subheading">
                <i>Maintenance and technical support </i>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul> -->
  </perfect-scrollbar>
</div>