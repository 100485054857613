<div style="position: relative">
  <ngx-ui-loader [loaderId]="'frameLoader-' + dashId" [fgsSize]="75" [hasProgressBar]="false">
  </ngx-ui-loader>
</div>
<ng-container *ngIf="!loading">
  <select *ngIf="sessions" [(ngModel)]="selectedSession">
    <option *ngFor="let session of sessions" [value]="session.sessionId">{{ session.date }}</option>
  </select>
  <iframe
    #myFrame
    id="appIFrame-{{ dashId }}"
    [src]="appSafeUrl"
    style="border: none; width: 100%"
    appIframeResizer
    scrolling="no"
    (load)="onIFrameLoad()"
  >
  </iframe>
</ng-container>
<ng-template #modalViewer let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-black-50">{{ modalTitle }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <app-image-viewer [s3Url]="selectedImage"></app-image-viewer>
  </div>
</ng-template>