import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoftwareLabelComponent } from './software-label.component';

@NgModule({
  declarations: [SoftwareLabelComponent],
  imports: [
    CommonModule
  ],
  exports: [SoftwareLabelComponent],
})
export class SoftwareLabelModule {}