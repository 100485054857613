export const environment = {
  production: true,
  testing: false,
  testApiUrl: 'postgrest-api.scpp-dev.sonrai.cloud',
  version: '1.5',
  product_owner_uuid: '3d8b42ba-b34c-4eb2-afc7-058e9ead6594',
  product_owner: 'scpp-dev',
  product_owner_shortname: 'scpp-dev',
  owner_tagline: '',
  product_name: 'InDRA',
  defaultLanguage: 'en',
  supportedLanguages: ['en'],
  serverUrl: 'node-api.scpp-dev.sonrai.cloud',
  secureServerUrl: 'secure-api.scpp-dev.sonrai.cloud',
  serverPort: '',
  serverProtocol: 'https',
  appServerUrl: 'https://apigateway-ds.scpp-dev.sonrai.cloud/',
  imageServerUrl: 'https://node-api.scpp-dev.sonrai.cloud/images/',
  uploadTransformUrl: 'https://data-transform.scpp-dev.sonrai.cloud/transform',
  socketUploadTransformUrl: 'https://data-transform.scpp-dev.sonrai.cloud/',
  s3Region: 'eu-west-2',
  paramStoreName: 'scpp-dev',
  redisEndpoint: 'scp-ca-67x99u2k83va.ad8nay.0001.euw2.cache.amazonaws.com:6379',
  maxUserParameterName: 'maxUserLimit',
  maintenanceMode: 'maintenance',
  sonraiImaging: 'sonraiImaging',
  s3ProjectDataPrefix: 'project-data-scpp-dev',
  s3DatasetDataPrefix: 'indra-datasets-scpp-dev',
  s3OrganisationAssetsPrefix: 'indra-scpp-dev-assets',
  cognitoSettings: {
    region: 'eu-west-2',
    identityPoolId: 'eu-west-2:9fb4d04e-941f-4c2f-b5f7-af8227852023',
    userPoolId: 'eu-west-2_2ipYInd0N',
    clientId: '7hveiok3mphjb4uigbkj2an3gp',
  },
  tileViewerUrl: 'https://1lxr0v2u8d.execute-api.eu-west-2.amazonaws.com/prod',
  extractorTriggerArn:
    'arn:aws:lambda:eu-west-2:753934879612:function:scpp_tile_extraction_trigger',
  algorithmTriggers: {
    msi: 'arn:aws:lambda:eu-west-2:753934879612:function:scpp_msi_detection_trigger',
  },
  oktaSettings: {
    clientId: '5qniob5hqj4nn4a9mthh92pqu6',
    redirectUri: 'http://localhost:4200/login?redirect=/dashboard',
    tokenUrl: 'https://secure-api.dev.indraapp.com/oidc',
    cognitoEndpoint: 'dev-jupyter.auth.eu-west-2.amazoncognito.com',
    userInfoUrl: 'https://dev-jupyter.auth.eu-west-2.amazoncognito.com/oauth2/userInfo',
  },
};
