import { Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { SubSink } from '@base/node_modules/subsink/dist/subsink';
import * as AWS from '@base/node_modules/aws-sdk';
import { AuthenticationService } from '../authentication/authentication.service';
import { HttpClient } from '@angular/common/http';
// const AWS = require('aws-sdk');
import { switchMap } from 'rxjs/operators';

AWS.config.update({
  region: environment.s3Region,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: environment.cognitoSettings.identityPoolId,
  }),
});
AWS.config.httpOptions.timeout = 0; // Overrides default 2 minute S3 Upload Time Limit

@Injectable({
  providedIn: 'root',
})
export class S3Service implements OnDestroy {
  uploadSubject = new Subject<any>();
  progressSubject = new Subject<any>();
  /**
   * ******************************** IMPORTANT UPDATE ********************************
   * Updated the S3 config to have updated credentials each function call
   * If this is done once in the constructor refreshed tokens are not matched in this service - breaking the whole system
   * This is the same for any other AWS services, as tokens need to be refreshed atleast once an hour to make any calls to AWS services
   **/
  s3 = new AWS.S3({
    apiVersion: '2006-03-01',
  });

  endpoint = 'https://je25zgsysc.execute-api.eu-west-2.amazonaws.com/indra/create';
  multiPartUploadSubject: Subject<any>;
  multiPartProgressSubject: Subject<any>;
  // Subscriptions
  private readS3Subscripton: Subscription;
  private deleteFromS3Subscripton: Subscription;
  private deleteMultipleS3Subscripton: Subscription;

  // Subsink to be used in the OnDestroy Lifecycle hook to destroy all active subscriptions
  private subscriptionContainer = new SubSink();
  constructor(private injector: Injector, private http: HttpClient) {}

  /**
   *  Ryan Duffy
   *
   *  uploadToS3
   *
   *  upload data to a specific S3 bucket - requires the S3 bucket to be setup (CORS, public etc.)
   *
   * @param bucket the name of the bucket to upload the data to
   * @param key the exact folder structure of the file to be uploaded includes it's own name i.e.
   *                                            s3://sonrai-r-data/INvigor210/nameofacsv.csv
   * @param acl the status of the object to upload i.e. public-read
   * @param body the file you wish to upload
   */
  uploadToS3(bucket: string, key: string, acl: any, body: any) {
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    this.s3
      .upload(
        {
          Bucket: bucket,
          Key: key,
          ACL: acl,
          Body: body,
        },
        { partSize: 10 * 1024 * 1024, queueSize: 1, leavePartsOnError: true }
      )
      .on('httpUploadProgress', (progress) => {
        this.progressSubject.next(progress);
      })
      .send((err, data) => {
        if (err) {
          this.uploadSubject.error(err);
          this.uploadSubject.complete();
        } else {
          this.uploadSubject.next(data);
          this.uploadSubject.complete();
        }
      });
  }
  /**
   *  Ryan Duffy
   *
   * @param {string} bucket the name of the bucket to be uploaded
   * @param {string} key full name of the object to be inserted including extension
   * @memberof S3Service
   */
  createMultiPartUpload(bucket: string, key: string): Observable<any> {
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    let uploadId: string;
    return new Observable((observer) => {
      this.s3.createMultipartUpload(
        {
          Bucket: bucket,
          Key: key,
        },
        (err, upload) => {
          if (err) {
            observer.error(err);
            observer.complete();
          } else {
            uploadId = upload.UploadId;
            observer.next(uploadId);
            observer.complete();
          }
        }
      );
    });
  }
  /**
   *
   *
   * @param body: any the actual file to upload
   * @param bucket: string the name of the bucket to be uploaded
   * @param key: string full name of the object to be inserted including extension
   * @param partNumber: number number of part to be uploaded in the multipart upload
   * @param uploadId: string the uniqueID generated for the upload to be made
   * @memberof S3Service
   */
  uploadPart(body: any, bucket: string, key: string, partNumber: number, uploadId: string) {
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    this.multiPartUploadSubject = new Subject<any>();
    this.multiPartProgressSubject = new Subject<any>();
    this.s3
      .uploadPart({
        Body: body,
        Bucket: bucket,
        Key: key,
        PartNumber: partNumber,
        UploadId: uploadId,
      })
      .on('httpUploadProgress', (progress) => {
        this.multiPartProgressSubject.next(progress);
        if (progress.loaded === progress.total) {
          this.multiPartProgressSubject.complete();
        }
      })
      .send((err, uploadResponse) => {
        if (err) {
          this.multiPartUploadSubject.error(err);
          this.multiPartUploadSubject.complete();
        } else {
          this.multiPartUploadSubject.next(uploadResponse.ETag);
          this.multiPartUploadSubject.complete();
        }
      });
  }
  /**
   *  differs from old methods it runs async using one observable and passing an object with multiple properties
   * Works better with modularised upload component
   *
   * @param body: any the actual file to upload
   * @param bucket: string the name of the bucket to be uploaded
   * @param key: string full name of the object to be inserted including extension
   * @param partNumber: number number of part to be uploaded in the multipart upload
   * @param uploadId: string the uniqueID generated for the upload to be made
   * @memberof S3Service
   */
  uploadPartAsync(
    body: any,
    bucket: string,
    key: string,
    partNumber: number,
    uploadId: string,
    noProgress?: boolean
  ): Observable<any> {
    const returnValue = { progress: null, eTag: null };
    let eTag: string;
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    return new Observable((observer) => {
      this.s3
        .uploadPart({
          Body: body,
          Bucket: bucket,
          Key: key,
          PartNumber: partNumber,
          UploadId: uploadId,
        })
        .on('httpUploadProgress', (progress) => {
          if (!noProgress) {
            returnValue.progress = progress;
            observer.next(returnValue);
          }
        })
        .send((err, uploadResponse) => {
          if (err) {
            observer.next(err);
            observer.complete();
          } else {
            eTag = uploadResponse.ETag;
            returnValue.eTag = eTag;
            observer.next(returnValue);
            observer.complete();
          }
        });
    });
  }
  /**
   *
   *
   * @param {string} bucket: string of the bucket to be insered into
   * @param {string} key full name of the object to be inserted including extension
   * @param {number} parts number of parts to be uploaded in the multipart upload
   * @param {string} uploadId the uniqueID generated for the upload to be made
   * @memberof S3Service
   */
  completeMultiPart(bucket: string, key: string, parts: any, uploadId: string): Observable<any> {
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    return new Observable((observer) => {
      this.s3.completeMultipartUpload(
        {
          Bucket: bucket,
          Key: key,
          MultipartUpload: { Parts: [parts] },
          UploadId: uploadId,
        },
        (errcomplete, completeResponse) => {
          if (errcomplete) {
            observer.error(errcomplete);
            observer.complete();
          } else {
            observer.next(completeResponse);
            observer.complete();
          }
        }
      );
    });
  }

  checkFileSize(bucket: string, key: string): Observable<any> {
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    return new Observable<any>((observer) => {
      this.s3.headObject(
        {
          Bucket: bucket,
          Key: key,
        },
        function (err, data) {
          if (err) {
            observer.error(err);
            observer.complete();
          } else {
            observer.next(data);
            observer.complete();
          }
        }
      );
    });
  }

  /**
   *  Ryan Duffy
   *
   *  readFromS3
   *
   *  Read a current listing of files inside an S3 bucket (Bucket permissions need modified for reading)
   *
   * @param bucket the name of the bucket to upload the data to
   * @param prefix the prefix of the files you need to access i.e. ${projectName}/Master/Metadata/
   * @param type: string type of files we are looking for
   */
  readFromS3(bucket: string, prefix: string, type: string): Observable<any> {
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    let delimiter: string;
    let maxKey: number;
    return new Observable((observer) => {
      // Due to differences in how we need to access data,
      // Situationally use the delimiter and set a max amount
      switch (type) {
        case 'projectData':
          delimiter = '/';
          maxKey = 5;
          break;
        case 'reportLog':
          delimiter = '/';
          break;
        case 'images':
          delimiter = '/images';
          break;
        case 'files':
          delimiter = '/';
          break;
      }
      this.s3.listObjects(
        {
          Bucket: bucket,
          Delimiter: delimiter,
          Prefix: prefix,
          MaxKeys: maxKey,
        },
        (err: AWS.AWSError, data) => {
          if (err) {
            observer.error(err);
            observer.complete();
          } else {
            observer.next(data);
            observer.complete();
          }
        }
      );
    });
  }
  /**
   *  Ryan Duffy
   *
   *  deleteFromS3
   *
   *  delete a specifc file from an S3 bucket
   *
   * @param bucket the name of the bucket to delete the data from
   * @param key the exact folder structure of the file to be delete, including it's own name i.e.
   *                                            s3://sonrai-r-data/INvigor210/nameofacsv.csv
   *
   */
  deleteFromS3(bucket, key): Observable<any> {
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    return new Observable((observer) => {
      this.s3.deleteObject(
        {
          Bucket: bucket,
          Key: key,
        },
        function (err, data) {
          if (err) {
            observer.error(err);
            observer.complete();
          } else {
            observer.next(data);
            observer.complete();
          }
        }
      );
    });
  }
  /**
   *  Ryan Duffy
   *
   *  delete as many items as requested in one go put in an array structured [{Key: item}, {Key: item2}]
   *
   * @param bucket: string
   * @param objects: any[]
   * @returns Observable<any>
   * @memberof S3Service
   */
  deleteMultipleFromS3(bucket: string, objects: any[]): Observable<any> {
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    return new Observable((observer) => {
      this.s3.deleteObjects(
        {
          Bucket: bucket,
          Delete: { Objects: objects },
        },
        (err, data) => {
          if (err) {
            observer.error(err);
            observer.complete();
          } else {
            observer.next(data);
            observer.complete();
          }
        }
      );
    });
  }
  /**
   *  Ryan Duffy
   *
   *  Remove a parent folder and ALL children content
   * Careful with useage could potentially purge a lot of client data
   *
   * @param bucketName: string
   * @param folderName: string
   * @returns Observable<any>
   * @memberof S3Service
   */
  emptyAndDeleteFolderObject(bucketName: string, folderName: string): Observable<any> {
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    const tobeDeleted: any[] = [];
    return new Observable((observer) => {
      this.subscriptionContainer.sink = this.readS3Subscripton = this.readFromS3(
        bucketName,
        `${folderName}/`,
        ''
      ).subscribe((folderDetails) => {
        if (!folderDetails.Contents.length) {
          this.deleteFromS3Subscripton = this.deleteFromS3(bucketName, `${folderName}`).subscribe(
            () => {
              if (this.deleteFromS3Subscripton) {
                this.deleteFromS3Subscripton.unsubscribe();
              }
            }
          );
          observer.next();
          observer.complete();
        } else {
          for (const item of folderDetails.Contents) {
            tobeDeleted.push({ Key: item.Key });
          }
          this.subscriptionContainer.sink = this.deleteMultipleS3Subscripton =
            this.deleteMultipleFromS3(bucketName, tobeDeleted).subscribe(
              (data) => {
                if (this.deleteMultipleS3Subscripton) {
                  this.deleteMultipleS3Subscripton.unsubscribe();
                }
                observer.next(data);
                observer.complete();
              },
              (err) => {
                observer.next(err);
                observer.complete();
              }
            );
        }
        if (this.readS3Subscripton) {
          this.readS3Subscripton.unsubscribe();
        }
      });
    });
  }
  /**
   *  Warren Dowey
   *  getS3Object
   *  converts UINt8Array data into a usable base64 image data, needs separated to service
   * @param bucket: string of the s3 bucket name
   * @param key: string of the AWS key to the file
   * @param range: limit of bytes to retrieve
   * @return observable<any>: observable of the AWS S3 call to get an object
   */
  getS3Object(bucket, key, range?): Observable<any> {
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    return new Observable((observer) => {
      this.s3.getObject(
        {
          Bucket: bucket,
          Key: key,
          Range: 'bytes=0-' + range,
        },
        function (err, data) {
          if (err) {
            observer.error(err);
          } else {
            observer.next(data);
            observer.complete();
          }
        }
      );
    });
  }
  abortMultiPartUpload(bucket, key, uploadId): Observable<any> {
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    return new Observable((observer) => {
      this.s3.abortMultipartUpload(
        { Bucket: bucket, Key: key, UploadId: uploadId },
        (err, data) => {
          if (err) {
            observer.next(err);
            observer.complete();
          } else {
            observer.next(data);
            observer.complete();
          }
        }
      );
    });
  }
  getFileUrl(bucket: string, key: string): string {
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    return this.s3.getSignedUrl('getObject', {
      Bucket: bucket,
      Key: key,
    });
  }

  getAssignedUrl(bucket: string, key: string): Observable<any> {
    return new Observable((subscriber) => {
      this.s3.config.update({
        credentials: this.injector.get(AuthenticationService).accessCredentials,
      });
      const longUrl = this.s3.getSignedUrl('getObject', {
        Bucket: bucket,
        Key: key,
        // one week
        Expires: 604800,
      });
      this.getShortenedUrl(longUrl).subscribe((res) => {
        subscriber.next(res);
      });
    });
  }

  getShortenedUrl(longUrl): Observable<any> {
    return new Observable((subscriber) => {
      const body = {
        long_url: longUrl,
      };
      this.http.post(this.endpoint, body).subscribe((res) => {
        subscriber.next(res);
      });
    });
  }

  /**
   *
   *
   * @param {string} bucket
   * @param {string} key
   * @param {File} csvFile
   * @memberof S3Service
   */
  fullS3MultiPartUpload(bucket: string, key: string, csvFile: File): Observable<any> {
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    let uploadId: string;
    return new Observable<any>((observer) => {
      this.subscriptionContainer.sink = this.createMultiPartUpload(bucket, key)
        .pipe(
          switchMap((returnId) => {
            uploadId = returnId;
            return this.uploadPartAsync(csvFile, bucket, key, 1, uploadId, true);
          }),
          switchMap((returnData) => {
            return this.completeMultiPart(
              bucket,
              key,
              { ETag: returnData.eTag, PartNumber: 1 },
              uploadId
            );
          })
        )
        .subscribe(
          (data) => {
            observer.next(data);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  putObject(bucket, key): Observable<any> {
    this.s3.config.update({
      credentials: this.injector.get(AuthenticationService).accessCredentials,
    });
    return new Observable((subscriber) => {
      const params = {
        Bucket: bucket,
        Key: key + '/',
      };
      this.s3.putObject(params, (err, data) => {
        if (data) {
          subscriber.next(data);
        }
      });
    });
  }

  listObjects(bucket: string, prefix: string, delimiter?: string): Observable<any> {
    const params = {
      Bucket: bucket,
      Prefix: prefix,
      Delimiter: delimiter,
    };
    return new Observable<any>((observer) => {
      this.s3.config.update({
        credentials: this.injector.get(AuthenticationService).accessCredentials,
      });
      this.s3.listObjectsV2(params, (err, data) => {
        if (err) {
          observer.error(err);
        } else {
          observer.next(data);
        }
      });
    });
  }

  moveObject(oldObjectKey: string, newObjectKey: string): Observable<any> {
    const params = {
      Bucket: environment.s3DatasetDataPrefix,
      CopySource: environment.s3ProjectDataPrefix + '/' + oldObjectKey,
      Key: newObjectKey,
    };
    return new Observable<any>((observer) => {
      this.s3.config.update({
        credentials: this.injector.get(AuthenticationService).accessCredentials,
      });
      this.s3.copyObject(params, (err, data) => {
        if (err) {
          observer.error(err);
        } else {
          observer.next(data);
        }
      });
    });
  }

  copyObject(params): Observable<any> {
    return new Observable<any>((observer) => {
      this.s3.config.update({
        credentials: this.injector.get(AuthenticationService).accessCredentials,
      });
      this.s3.copyObject(params, (err, data) => {
        if (err) {
          observer.error(err);
        } else {
          observer.next(data);
        }
      });
    });
  }

  ngOnDestroy() {
    this.subscriptionContainer.unsubscribe();
  }
}
