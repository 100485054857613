import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class RedisService {
  redisEndpoint = environment.redisEndpoint;

  constructor(private http: HttpClient) {}

  uploadRedis(
    redisUuid: string,
    url: string[],
    destinationBucket: string,
    userId: string,
    productOwner: string,
    datasetId?: string,
    projectSlug?: string,
    dataId?: string,
    sessionId?: string,
    workflowId?: string,
    step?: number,
    steps?: number,
    databaseUrl?: string,
    token?: string,
    clientId?: string,
    userPoolId?: string,
    region?: string
  ): Observable<any> {
    return new Observable<any>((subscriber) => {
      region = environment.s3Region;
      const params = {
        appParamStoreId: redisUuid,
        rawDataUrls: url,
        datasetId: datasetId,
        projectSlug: projectSlug,
        destinationBucket: destinationBucket,
        userId: userId,
        dataId: dataId,
        sessionId: sessionId,
        workflowId: workflowId,
        step: step,
        steps: steps,
        databaseUrl: databaseUrl,
        token: token,
        clientId: clientId,
        userPoolId: userPoolId,
        region: region,
        productOwner: productOwner,
        paramStoreName: environment.paramStoreName,
      };
      this.http.post(this.redisEndpoint, params).subscribe(
        (res) => {
          subscriber.next(res);
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }
}
