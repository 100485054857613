import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';

import { HttpCacheService } from './http-cache.service';
import { UtilitiesService } from '../shared-services/utilities.service';

/**
 * Caches HTTP requests.
 * Use ExtendedHttpClient fluent API to configure caching for each request.
 */
@Injectable({ providedIn: 'root' })
export class CacheInterceptor implements HttpInterceptor {
  // Due to a lot of pre existing endpoints having their own lists with complex logic, only use this for selected services going forward
  private includedEndpoints = ['/projects', '/project_details', '/data_cache', '/roles', 'sonrai-tiling'];
  constructor(private httpCacheService: HttpCacheService, private utilities: UtilitiesService) {}
  /**
   *
   *  intercept
   *
   *  executes the cache interceptor
   *
   * @param request Takes the details of the Http request
   * @param next Takes the HTTPHanlder
   *
   * @return Observable<HttpEvent<any>> of the cached HTTP response or error details.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== 'GET') {
      return next.handle(request);
    }
    return new Observable((subscriber: Subscriber<HttpEvent<any>>) => {
      const cachedData = this.httpCacheService.getCacheData(request.urlWithParams);
      if (cachedData !== null) {
        subscriber.next(new HttpResponse(cachedData as Object));
        subscriber.complete();
      } else {
        next.handle(request).subscribe(
          (event) => {
            if (
              event instanceof HttpResponse &&
              this.utilities.stringInArray(this.includedEndpoints, event.url)
            ) {
              this.httpCacheService.setCacheData(request.urlWithParams, event);
            }
            subscriber.next(event);
          },
          (error) => subscriber.error(error),
          () => subscriber.complete()
        );
      }
    });
  }
}
