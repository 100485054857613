export class SettingsModel {
  organisationId: string;
  appLogo: string;
  displayProjects: boolean;
  displayDatasets: boolean;
  displayCases: boolean;
  displayImaging: boolean;
  imagingUrl: string;
  displayDataUiApp: boolean;

  constructor(
    organisationId?: string,
    appLogo?: string,
    displayProjects?: boolean,
    displayDatasets?: boolean,
    displayCases?: boolean,
    displayImaging?: boolean,
    imagingUrl?: string,
    displayDataUiApp?: boolean
  ) {
    this.organisationId = organisationId;
    this.appLogo = appLogo;
    this.displayProjects = displayProjects;
    this.displayDatasets = displayDatasets;
    this.displayCases = displayCases;
    this.displayImaging = displayImaging;
    this.imagingUrl = imagingUrl;
    this.displayDataUiApp = displayDataUiApp;
  }

  toJson(): Object {
    const object = {
      organisation_id: this.organisationId,
      app_logo: this.appLogo,
      display_projects: this.displayProjects,
      display_datasets: this.displayDatasets,
      display_cases: this.displayCases,
      display_imaging: this.displayImaging,
      imaging_url: this.imagingUrl,
      display_data_ui_app: this.displayDataUiApp,
    };
    return object;
  }

  fromJson(input: any): SettingsModel {
    return new SettingsModel(
      input['organisation_id'],
      input['app_logo'],
      input['display_projects'],
      input['display_datasets'],
      input['display_cases'],
      input['display_imaging'],
      input['imaging_url'],
      input['display_data_ui_app']
    );
  }

}
