import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CognitoIdentityServiceProvider } from 'aws-sdk/clients/all';
import * as AWS from '@base/node_modules/aws-sdk';
import { AuthenticationService } from '../authentication/authentication.service';
import { Logger } from '@app/services/logger/logger.service';
const log = new Logger('TokenRefreshService');

// const AWS = require('aws-sdk');
@Injectable({
  providedIn: 'root',
})
export class CognitoAdminUtilsService {
  constructor(private auth: AuthenticationService) {}
  /** Ryan Duffy
   *
   *  adminCreateUser
   *
   *  allow an admininstrative user to create new users for their organisation
   *
   * @param username string of the new username to be created
   * @param email string of the new email to be added to the user's details
   * @param firstName
   * @param familyName

   *
   * @param messageAction
   * @returns void creates the new user in cognito with the given params
   */
  adminCreateUser(
    username: string,
    email: string,
    firstName: string,
    familyName: string,
  ) {
    // // Update the AWS config for admin functions with access key and secret access key
    AWS.config.update({
      credentials: this.auth.accessCredentials,
    });
    // Create a new instance of an AWS CognitoIdentityServiceProvider
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

    // Params required for adminCreateUser()
    const userParams = {
      UserPoolId: environment.cognitoSettings.userPoolId,
      Username: `${username}`,
      DesiredDeliveryMediums: ['EMAIL'],
      ForceAliasCreation: true,
      UserAttributes: [
        {
          Name: 'email',
          Value: `${email}`,
        },
        {
          Name: 'name',
          Value: `${firstName}`,
        },
        {
          Name: 'family_name',
          Value: `${familyName}`,
        },
        {
          Name: 'email_verified',
          Value: 'true',
        },
      ],
    };
    return new Observable((observer) => {
      cognitoidentityserviceprovider.adminCreateUser(
        userParams,
        (err, data: CognitoIdentityServiceProvider.AdminCreateUserResponse) => {
          if (err) {
            observer.error(err);
            observer.complete();
          } else {
            observer.next(data);
            observer.complete();
          }
        }
      );
    });
  }

  requestNewPassword(username): Observable<any> {
    return new Observable<any>(subscriber => {
      const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      const userParams = {
        UserPoolId: environment.cognitoSettings.userPoolId,
        Username: username,
        DesiredDeliveryMediums: ['EMAIL'],
        ForceAliasCreation: true,
        MessageAction: 'RESEND',
        UserAttributes: [
          {
            Name: 'name',
            Value: username,
          },
        ],
      };
      cognitoidentityserviceprovider.adminCreateUser(
        userParams,
        (err, data: CognitoIdentityServiceProvider.AdminCreateUserResponse) => {
          if (err) {
            log.error(err);
            subscriber.next(err);
          } else {
            log.info('New temporary password sent');
            subscriber.next(data);
          }
        });
    })

  }
  /**
   *  update one specific attribute for the currently logged in user
   *
   * @param {string} attributeName
   * @param {string} attributeValue
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  adminUpdateUserAttribute(
    username: string,
    attributeName: string,
    attributeValue: string
  ): Observable<any> {
    const params = {
      UserPoolId: environment.cognitoSettings.userPoolId,
      Username: `${username}`,
      UserAttributes: [
        {
          Name: attributeName,
          Value: attributeValue,
        },
      ],
    };
    AWS.config.update({
      credentials: this.auth.accessCredentials,
    });
    let cognitoIdentityServiceProvider: CognitoIdentityServiceProvider;
    cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();
    return new Observable((observer) => {
      cognitoIdentityServiceProvider.adminUpdateUserAttributes(params, (err: any, data: any) => {
        if (err) {
          observer.error(err);
          observer.complete();
        } else {
          observer.next(data);
          observer.complete();
        }
      });
    });
  }

  /**
   *
   *  update the user's preference concerning MFA
   *
   * @param {boolean} enable
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  adminUpdateUserMFAPreference(username: string, enable: boolean): Observable<any> {
    let params;
    let cognitoIdentityServiceProvider: CognitoIdentityServiceProvider;
    if (enable) {
      params = {
        UserPoolId: environment.cognitoSettings.userPoolId,
        Username: `${username}`,
        MFAOptions: [
          /* required */
          {
            AttributeName: 'phone_number',
            DeliveryMedium: 'SMS',
          },
        ],
      };
    } else {
      params = {
        UserPoolId: environment.cognitoSettings.userPoolId,
        Username: `${username}`,
        MFAOptions: [],
      };
    }
    AWS.config.update({
      credentials: this.auth.accessCredentials,
    });
    cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();
    return new Observable((observer) => {
      cognitoIdentityServiceProvider.adminSetUserSettings(params, (err: any, data: any) => {
        if (err) {
          observer.error(err);
          observer.complete();
        } else {
          observer.next(data);
          observer.complete();
        }
      });
    });
  }

  /**
   *
   *
   * @param {string} username
   * @returns
   * @memberof AuthenticationService
   */
  adminDisableUser(username: string) {
    const userParams = {
      UserPoolId: environment.cognitoSettings.userPoolId,
      Username: `${username}`,
    };
    let cognitoidentityserviceprovider: CognitoIdentityServiceProvider;
    // Update the AWS config for admin functions with access key and secret access key
    AWS.config.update({
      credentials: this.auth.accessCredentials,
    });
    // Create a new instance of an AWS CognitoIdentityServiceProvider
    cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    return new Observable((observer) => {
      cognitoidentityserviceprovider.adminDisableUser(userParams, (err, data) => {
        if (err) {
          observer.error(err);
          observer.complete();
        } else {
          observer.next(data);
          observer.complete();
        }
      });
    });
  }
  adminEnableUser(username: string) {
    const userParams = {
      UserPoolId: environment.cognitoSettings.userPoolId,
      Username: `${username}`,
    };
    let cognitoidentityserviceprovider: CognitoIdentityServiceProvider;
    // Update the AWS config for admin functions with access key and secret access key
    AWS.config.update({
      credentials: this.auth.accessCredentials,
    });
    // Create a new instance of an AWS CognitoIdentityServiceProvider
    cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    return new Observable((observer) => {
      cognitoidentityserviceprovider.adminEnableUser(userParams, (err, data) => {
        if (err) {
          observer.error(err);
          observer.complete();
        } else {
          observer.next(data);
          observer.complete();
        }
      });
    });
  }
  /**
   * Provide a username and password to validate if they are correct for a user without using the login process.
   *
   * @param username
   * @param password
   * @returns
   */
  adminValidateCredentials(username: string, password: string) {
    const params: CognitoIdentityServiceProvider.AdminInitiateAuthRequest = {
      UserPoolId: environment.cognitoSettings.userPoolId,
      ClientId: environment.cognitoSettings.clientId,
      AuthFlow: 'ADMIN_NO_SRP_AUTH',
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password,
      },
    };
    let cognitoidentityserviceprovider: CognitoIdentityServiceProvider;
    // Update the AWS config for admin functions with access key and secret access key
    AWS.config.update({
      credentials: this.auth.accessCredentials,
    });
    // Create a new instance of an AWS CognitoIdentityServiceProvider
    cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    return new Observable((observer) => {
      cognitoidentityserviceprovider.adminInitiateAuth(params, (err, data) => {
        if (err) {
          observer.error(err);
          observer.complete();
        } else {
          observer.next(data);
          observer.complete();
        }
      });
    });
  }
  /**
   * Delete a cognito user fro the userpool
   *
   * @param username
   * @param password
   * @returns
   */
  adminDeleteUser(username: string) {
    const params: CognitoIdentityServiceProvider.AdminDeleteUserRequest = {
      UserPoolId: environment.cognitoSettings.userPoolId,
      Username: username,
    };
    let cognitoidentityserviceprovider: CognitoIdentityServiceProvider;
    // Update the AWS config for admin functions with access key and secret access key
    AWS.config.update({
      credentials: this.auth.accessCredentials,
    });
    // Create a new instance of an AWS CognitoIdentityServiceProvider
    cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    return new Observable((observer) => {
      cognitoidentityserviceprovider.adminDeleteUser(params, (err, data) => {
        if (err) {
          observer.error(err);
          observer.complete();
        } else {
          observer.next(data);
          observer.complete();
        }
      });
    });
  }
  /**
   *
   *
   *  adminResetUserPassword
   *
   *  Resets the specified user's password.
   *
   * @param Username as a string, the user's username
   * @param UserPoolId as a string, the user pool ID for the user pool where you want to reset the user's password.
   *
   * @returns: void: resets the user's password if no errors occur
   *
   */
  adminResetUserPassword(Username: string): Observable<any> {
    const params = {
      Username: Username,
      UserPoolId: environment.cognitoSettings.userPoolId,
    };
    const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();
    return new Observable((observer) => {
      cognitoIdentityServiceProvider.adminResetUserPassword(params, (err: any, data: any) => {
        if (err) {
          observer.error(err);
          observer.complete();
        } else {
          observer.next(data);
          observer.complete();
        }
      });
    });
  }
  /**
   *
   *
   *  adminSetUserPassword
   *
   *  Resets the specified user's password.
   *
   * @param Username as a string, the user's username
   * @param UserPoolId as a string, the user pool ID for the user pool where you want to reset the user's password.
   *
   * @returns: void: resets the user's password if no errors occur
   *
   */
  adminSetUserPassword(Password: string, Permanent: boolean, Username: string): Observable<any> {
    const params = {
      Password: Password,
      Permanent: Permanent,
      Username: Username,
      UserPoolId: environment.cognitoSettings.userPoolId,
    };
    const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();
    return new Observable((observer) => {
      cognitoIdentityServiceProvider.adminSetUserPassword(params, (err: any, data: any) => {
        if (err) {
          observer.error(err);
          observer.complete();
        } else {
          observer.next(data);
          observer.complete();
        }
      });
    });
  }
}
