// import { AppComponent, modules } from './app.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

/* Essential core Angular Modules Required for Base Components */
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';

/* Our own key modules require*/
// import { UserNotificationsModule } from '@app/components/shared/user-notifications/user-notifications.module';
// import { MyNotesModule } from '@app/components/shared/my-notes/my-notes-module';

/* Overall Layout components required*/
import { AppsLayoutComponent } from '@app/components/layouts/apps-layout/apps-layout.component';
import { BaseLayoutComponent } from './components/layouts/base-layout/base-layout.component';
import { BlankLayoutComponent } from '@app/components/layouts/blank-layout/blank-layout.component';
import { SidebarComponent } from '@app/components/layouts/sidebar/sidebar.component';
import { UserBoxComponent } from './components/layouts/header/elements/user-box/user-box.component';

/* our own services*/
import { ValidateService } from '@app/services/validate/validate.service';
import { AuthUserService } from '@app/services/authentication/auth-user-service';
import { AuthSessionRecorderService } from '@app/services/authentication/auth-session-recorder-service';

/* external components and modules */
import { Ng7DynamicBreadcrumbModule } from 'ng14-dynamic-breadcrumb';
import { AvatarModule as NgxAvatarModule } from 'ngx-avatars';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { I18nService } from '@app/services/i18n/i18n.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgbModalModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageLoadedDirective } from '@app/components/shared/directives/image-loaded.directive';
import { ConfirmChangesGuard } from '@app/guards/confirm-changes.guard';
import { ClickOutsideDirective } from '@app/directives/click-outside-directive/click-outside.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppLoaderModule } from '@app/components/app-loader/app-loader.module';
import { ActivityTrackerDirectiveModule } from '@app/directives/activity-tracker-directive//activity-tracker-directive.module';
import { LoginModalComponent } from '@app/components/login-modal/login-modal.component';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { OfflineComponent } from '@app/offline/offline.component';
import { SentryErrorHandler } from '@app/services/shared-services/sentry-error-handler.service';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { AuthenticationGuard } from '@app/services/authentication/authentication.guard';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http';
import { TokenRefreshService } from '@app/services/aws-services/token-refresh.service';
import { ApiPrefixInterceptor } from '@app/services/http/api-prefix.interceptor';
import { HistoryInterceptor } from '@app/services/http/history-interceptor.service';
import { NotificationsInterceptorService } from '@app/services/http/notifications-interceptor.service';
import { CacheInterceptor } from '@app/services/http/cache.interceptor';
export function tokenGetter() {
  return localStorage.getItem('id_token');
}
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { SupportComponent } from '@app/components/shared/support/support.component';
import { MaintenanceModule } from '@app/components/maintenance/maintenance.module';
import { TopbarComponent } from '@app/components/layouts/topbar/topbar.component';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { NotificationsComponent } from '@app/components/shared/notifications/notifications.component';
import { SoftwareLabelModule } from '@app/components/shared/software-label/software-label.module';

@NgModule({
  declarations: [
    AppComponent,
    BaseLayoutComponent,
    BlankLayoutComponent,
    AppsLayoutComponent,
    SidebarComponent,
    UserBoxComponent,
    ImageLoadedDirective,
    ClickOutsideDirective,
    LoginModalComponent,
    OfflineComponent,
    SupportComponent,
    TopbarComponent,
    NotificationsComponent,
  ],
  imports: [
    AppRoutingModule,
    // Core Angular Modules
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    // External Modules required for custom components
    Ng7DynamicBreadcrumbModule,
    TranslateModule.forRoot(),
    NgxAvatarModule.forRoot(),
    PerfectScrollbarModule,
    MatTooltipModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    InfiniteScrollModule,
    // UserNotificationsModule,
    // MyNotesModule,
    NgbPopoverModule,
    NgbModalModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSnackBarModule,
    NgxUiLoaderModule,
    AppLoaderModule,
    ActivityTrackerDirectiveModule,
    FormsModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatListModule,
    MatExpansionModule,
    // MyTasksModule,
    MaintenanceModule,
    MatListModule,
    ApolloModule,
    SoftwareLabelModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HistoryInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationsInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    ValidateService,
    AuthUserService,
    AuthSessionRecorderService,
    AuthenticationService,
    AuthenticationGuard,
    TranslateService,
    ConfirmChangesGuard,
    I18nService,
    TokenRefreshService,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache({
            addTypename: false
          }),
          link: httpLink.create({
            uri: 'https://cqulsfwqwraxfp7irw5dywtepi.appsync-api.eu-west-2.amazonaws.com/graphql',
            headers: new HttpHeaders({"x-api-key": "da2-4t7uwm62qvam7oixut4bb7mkh4"})
          }),
        };
      },
      deps: [HttpLink],
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
