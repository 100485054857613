import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { S3Service } from '../aws-services/s3.service';
import { environment } from '@env/environment';
import { OrganisationCognitoUser } from '@app/models/cognito-organisation-user.model';
@Injectable({
  providedIn: 'root',
})
export class ImageParserService {
  constructor(private injector: Injector) {}

  parse(fullUrl: string): Observable<string> {
    const s3 = this.injector.get(S3Service);
    if (fullUrl) {
      const urlSplit = fullUrl.split('/');
      const imageKey = urlSplit.slice(3, urlSplit.length).join('/');
      return new Observable<string>((observer) => {
        s3.getS3Object(environment.s3OrganisationAssetsPrefix, imageKey).subscribe(
          (data) => {
            const binaryImage = data.Body.toString('base64');
            const imageString = 'data:image/jpeg;base64,' + binaryImage;
            observer.next(imageString);
          },
          (error) => {
            observer.next('');
          }
        );
      });
    } else {
      return of('');
    }
  }

  parseArrayUrls(fullUsers: OrganisationCognitoUser[]): Observable<OrganisationCognitoUser[]> {
    const users: OrganisationCognitoUser[] = [];
    return new Observable<OrganisationCognitoUser[]>((observer) => {
      for (const user of fullUsers) {
        this.parse(user.displayPicture).subscribe((encodedImage) => {
          const returnData = encodedImage ?? '';
          user.pictureBase64 = returnData;
          users.push(user);
          if (users.length === fullUsers.length) {
            observer.next(users);
            observer.complete();
          }
        });
      }
    });
  }
}
