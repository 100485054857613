<app-topbar [topbarClass]="{ 'topbar-expanded': expandSubmenu === true }">
  <div class="bread-crumb-override text-capitalize margin-override" class="topbar-inactive-submenu">
    <app-ng7-dynamic-breadcrumb
      [bgColor]="breadcrumbConfig['bgColor']"
      [fontSize]="breadcrumbConfig['fontSize']"
      [fontColor]="breadcrumbConfig['fontColor']"
      [lastLinkColor]="breadcrumbConfig['lastLinkColor']"
      [symbol]="breadcrumbConfig['symbol']"
    ></app-ng7-dynamic-breadcrumb>
  </div>
  <!-- Icons and profile management -->
  <div class="float-right">
    <!-- Search Box -->
    <!-- <div class="vert-spacer topbar-search-box">
      <div class="input-group">
        <input
          class="form-control search-box"
          type="text"
          placeholder="Search..."
          matInput
        />
      </div>
    </div> -->
    <!-- Shortcut Icons -->
    <div class="topbar-item" (click)="openDisplay()">
      <mat-icon matTooltip="Support" class="mini-icon topbar-icon">support</mat-icon>
      <!-- Support Menu -->
      <ng-container *ngIf="isShow" class="z-index-modal-3">
        <div
          (clickOutside)="closeWindow($event)"
          class="header-dropdown-menu-userbox"
        >
          <!-- Menu Body -->
          <div class="card box-bottom">
            <perfect-scrollbar [autoPropagation]="true">
              <ul class="support-list">
                <li class="category-text">Support</li>
                <li>
                  <a (click)="openLargeModal(modalSupport, 'xl')" class="list-link">Customer Support</a>
                </li>
                <li>
                  <a (click)="openLargeModal(softwareLabelModal, 'lg')" class="list-link">View Software Label</a>
                </li>
              </ul>
            </perfect-scrollbar>
          </div>
        </div>
      </ng-container>
    </div>
    <div
      class="topbar-item"
      matTooltip="Notifications"
      (click)="openLargeModal(newNotificationsModal, 'xl')"
    >
      <div
        *ngIf="!!notificationService.unreadNotificationCount"
        class="badge badge-pill badge-primary notification-badge"
      >
        {{ notificationService.unreadNotificationCount }}
      </div>
      <mat-icon class="mini-icon topbar-icon">notifications</mat-icon>
    </div>
    <!-- Profile Icon / Profile Management -->
    <div class="topbar-item">
      <div class="topbar-user-box">
        <app-user-box
          #userBox
          [userBoxClass]="userBoxClass"
          [sidebarUserBox]="false"
          [avatarSize]="24"
          [showBadge]="false"
        >
        </app-user-box>
      </div>
    </div>
  </div>
</app-topbar>
<app-sidebar
  [isMedicalDevice]="true"
  [expandSubmenu]="expandSubmenu"
  (onMenuToggle)="setSubmenu($event)"
></app-sidebar>
<!-- <div class="page-outer" [ngClass]="{'page-active-submenu': expandSubmenu === true}"> -->
<div class="page-outer" [ngClass]="{ 'page-active-submenu': expandSubmenu === true }">
  <div class="page-inner">
    <div class="online-status">
      <b *ngIf="!onlineStatus" class="text-danger">
        No Network connection, please reconnect to ensure you don't lose any work.
      </b>
      <b *ngIf="backOnlineUpdate" class="text-success"> Back online! </b>
      <b class="text-success"></b>
    </div>
    <router-outlet appActivityTracker #o="outlet"></router-outlet>
    <!-- </div> -->
  </div>
</div>

<!-- Support Modal -->
<ng-template #modalSupport let-c="close" let-d="dismiss">
  <div class="modal-header">
    <mat-icon class="icon project-icon-lg">support</mat-icon>
    <h5 class="modal-title side-spacer">Support</h5>
    <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="scroll-area-lg col-vh-height">
    <app-support> </app-support>
  </div>
</ng-template>

<!-- Notifications Modal -->
<ng-template #newNotificationsModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <span class="top-title">New Notifications</span>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-notifications></app-notifications>
</ng-template>

<!-- Software Label Modal -->
<ng-template #softwareLabelModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-software-label></app-software-label>
  </div>
</ng-template>
