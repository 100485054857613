<div class="login-container">
  <div class="modal-content">
    <div class="modal-body">
      <div class="mb-5">
        <h4 class="text-center">Session Timeout</h4>
        <h6>
          Your session has expired due to inactivity. Stay logged in to pick up where you left off.
        </h6>
      </div>
      <ng-container *ngIf="!loading">
        <form (ngSubmit)="unlockSession()" novalidate autocomplete="off">
          <div role="group" aria-describedby="username" class="form-group my-3">
            <div>
              <input
                id="username"
                type="text"
                placeholder="Username"
                [disabled]="true"
                required="required"
                aria-required="true"
                maxlength="32"
                class="form-control form-control-lg"
                aria-describedby="usernameInput"
                [(ngModel)]="username"
                name="username"
                autocomplete="off"
              />
            </div>
          </div>
          <div role="group" class="form-group my-3">
            <div>
              <input
                id="password"
                type="password"
                placeholder="Password"
                required="required"
                minlength="8"
                maxlength="32"
                aria-required="true"
                class="form-control form-control-lg"
                [(ngModel)]="password"
                name="password"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-group">
            <h4>
              <button type="submit" class="btn btn-primary btn-lg btn-block btn-pill">
                Stay Logged In
              </button>
            </h4>
          </div>
          <button (click)="backToLogin()" class="btn btn-link btn-lg pull-right">Sign Out</button>
        </form>
      </ng-container>
      <ng-container *ngIf="loading">
        <mat-spinner></mat-spinner>
        <div class="text-center">Logging in...</div>
      </ng-container>
    </div>
  </div>
</div>
