import { gql } from 'apollo-angular';

export const GET_MY_NOTIFICATIONS = gql`
  query getMyNotifications($condition: NotificationUserCondition!) {
    notificationUsers(condition: $condition, first: 99) {
      notificationId
      userId
      notification {
        created
        notificationId
        notificationRead
        notificationSourceId
        notificationSourceName
        notificationType
        ownerId
        updated
      }
    }
  }
`