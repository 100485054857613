import { Injectable } from '@angular/core';
import { OrganisationModel } from '@app/models/organisation.model';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { GET_ORGANISATION_BY_ID, UPDATE_ORGANISATION } from './organisation-queries';
@Injectable({
  providedIn: 'root',
})
export class OrganisationService {

  public organisation: OrganisationModel;

  constructor(
    private apollo: Apollo
  ) { }

  public getOrganisationById(organisationId: string, refresh?: boolean): Observable<OrganisationModel> {
    if (!!this.organisation && !refresh) {
      return new Observable(subscriber => {
        subscriber.next(this.organisation);
      });
    } else {
      return new Observable(subscriber => {
        this.apollo.query(
          {
            query: GET_ORGANISATION_BY_ID,
            variables: {
              organisationId: organisationId
            }
          }
        ).subscribe((orgResult: any) => {
          this.organisation = Object.assign(new OrganisationModel(), orgResult.data.organisation);
          subscriber.next(this.organisation);
        });
      });
    }
  }

  public updateData(orgUpdate: OrganisationModel): Observable<String> {
    return new Observable(subscriber => {
      this.apollo.mutate({
        mutation: UPDATE_ORGANISATION,
        variables: {
          input: {
            patch: orgUpdate,
            organisationId: orgUpdate.organisationId
          }
        }
      })
    });
  }

}
