import { gql } from "apollo-angular";

export const GET_ROLE_PERMISSIONS_BY_ROLEID = gql`
  query getRolePermissionsByRoleId($condition: RolePermissionsDetailCondition!) {
    rolePermissionsDetails(condition: $condition) {
      roleId
      permissionId
      permissionDetails
      isEnabled
    }
  }
`


export const GET_ROLE_PERMISSIONS_BY_ROLEID_AND_PERMISSIONID = gql`
  query getRolePermissionsByRoleId($condition: RolePermissionsDetailCondition!) {
    rolePermissionsDetails(condition: $condition) {
      roleId
      permissionId
      permissionDetails
      isEnabled
    }
  }
`




