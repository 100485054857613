<ng-container *ngIf="user">
  <div class="user-box-container">
    <div [ngClass]="userBoxClass">
      <button type="button" class="btn btn-link text-center p-0" (click)="openDisplay()">
        <div
          class="avatar-circle"
          mat-raised-button
          matTooltipPosition="below"
          matTooltip="{{ user.firstName }} {{ user.familyName }}"
        >
          <ngx-avatars
            [src]="userImage | async"
            [name]="userFullName"
            [size]="avatarSize"
            [initialsSize]="3"
            [bgColor]="user.favouriteColour || '#2DD070'"
          >
          </ngx-avatars>
        </div>
      </button>
    </div>

    <!-- Profile Menu -->
    <ng-container *ngIf="isShow" class="z-index-modal-3">
      <div
        (clickOutside)="closeWindow($event)"
        [ngClass]="{
          'dropdown-menu-userbox': sidebarUserBox === true,
          'header-dropdown-menu-userbox': sidebarUserBox === false
        }"
      >
        <div class="box-top">
          <img
            *ngIf="platformLogo"
            class="org-logo vert-spacer"
            [src]="platformLogo | async"
            alt=""
          />
          <div class="top-text">Hi, {{ user.firstName }} {{ user.familyName }}</div>
          <div class="top-text opacity-8">{{ user.email }}</div>
          <!-- TODO: Replace with Org Logo? -->
        </div>

        <!-- Menu Body -->
        <div class="card box-bottom">
          <perfect-scrollbar [autoPropagation]="true">
            <ul class="userbox-list">
              <li class="category-text">Account Management</li>
              <li>
                <a [routerLink]="['/profile']" class="list-link">My Profile </a>
              </li>

              <li>
                <a [routerLink]="['/change-password']" class="list-link">Change Password</a>
              </li>

              <li>
                <a (click)="logout()" class="list-link btn-logout">Logout</a>
              </li>
            </ul>
          </perfect-scrollbar>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
