import { gql } from "apollo-angular";

export const GET_ORGANISATION_BY_ID = gql`
  query getOrganisationById($organisationId: ID!) {
    organisation(organisationId: $organisationId) {
      createdAt
      creditsUpdated
      organisationApproved
      organisationBuilding
      organisationCounty
      organisationCredits
      organisationCss
      organisationId
      organisationLogo
      organisationName
      organisationOwner
      organisationPostcode
      organisationShortname
      organisationStandalone
      organisationStrapline
      organisationStreet
      organisationTown
      packageId
      parentId
      paymentStatus
      paymentUpdated
      platformLogo
      updatedAt
    }
  }
`

export const UPDATE_ORGANISATION = gql`
  mutation updateOrganisation($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      createdAt
      creditsUpdated
      organisationApproved
      organisationBuilding
      organisationCounty
      organisationCredits
      organisationCss
      organisationId
      organisationLogo
      organisationName
      organisationOwner
      organisationPostcode
      organisationShortname
      organisationStandalone
      organisationStrapline
      organisationStreet
      organisationTown
      packageId
      parentId
      paymentStatus
      paymentUpdated
      platformLogo
      updatedAt
    }
  }
`