import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Observable, fromEvent, merge, EMPTY, Subject, BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { mapTo, startWith } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  connectionMonitor: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  /**
   * credit to: https://stackoverflow.com/questions/46915717/how-can-you-detect-network-connectivity-in-angular-2
   * Elegant solution that update an observable every time the online status changes in browser so we can inform the user
   *
   * @param platform
   */
  constructor(@Inject(PLATFORM_ID) platform) {
    if (isPlatformBrowser(platform)) {
      const offline$ = fromEvent(window, 'offline').pipe(
        startWith(!window.navigator.onLine),
        mapTo(false)
      );
      const online$ = fromEvent(window, 'online').pipe(
        startWith(window.navigator.onLine),
        mapTo(true)
      );
      merge(offline$, online$).subscribe((data) => {
        this.connectionMonitor.next(data);
      });
    } else {
      EMPTY.subscribe((data) => {
        this.connectionMonitor.next(data);
      });
    }
  }

  monitor(): Observable<boolean> {
    return this.connectionMonitor.asObservable();
  }
}
