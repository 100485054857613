import { gql } from 'apollo-angular';

export const CREATE_SESSION_HISTORY = gql`
  mutation createSessionHistory($input: CreateSessionHistoryInput!) {
    createSessionHistory(input: $input) {
      createdAt
      sessionHistoryId
      sessionId
      updatedAt
      userId
    }
  }
`
export const GET_SESSION_HISTORY_BY_ID = gql`
  query getSessionHistoryById($sessionHistoryId: ID = "") {
    sessionHistory(sessionHistoryId: $sessionHistoryId) {
      createdAt
      sessionHistoryId
      sessionId
      userId
      updatedAt
    }
  }
`