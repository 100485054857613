import { gql } from 'apollo-angular';

export const GET_SETTINGS = gql`
  query getSettings($condition: SettingCondition!) {
    settings(condition: $condition) {
      appLogo
      displayCases
      displayDataUiApp
      displayDatasets
      displayImaging
      displayProjects
      imagingUrl
      organisationId
    }
  }
`

export const UPDATE_SETTING = gql`
  mutation updateSetting($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      appLogo
      displayCases
      displayDataUiApp
      displayDatasets
      displayImaging
      displayProjects
      imagingUrl
      organisationId
    }
  }
`