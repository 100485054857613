import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IFrameResizerDirective } from './iframe-resizer.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [IFrameResizerDirective],
  exports: [IFrameResizerDirective],
})
export class IframeResizerDirectiveModule {}
