import * as Sentry from '@sentry/angular';
// If taking advantage of automatic instrumentation (highly recommended)
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { MaterialSnackbarService } from './material-snackbar.service';
import { Logger } from '../logger/logger.service';
import { ErrorHandler, Injectable } from '@angular/core';
import { ConnectionService } from './connection.service';
const log = new Logger();

Sentry.init({
  dsn: 'https://8a1abe5e01324268a1f01cddd210dcb6@o717688.ingest.sentry.io/5780212',
  environment: `${environment.product_owner_shortname}`,
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: 'indra-ui:' + environment.version,
  beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    if (
      environment.product_owner_shortname === 'Staging' &&
      !environment.production
    ) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(
    private materialSnackbarService: MaterialSnackbarService,
    private onlineService: ConnectionService
  ) {}
  handleError(error) {
    let errorMessage: string;
    this.onlineService.monitor().subscribe((onlineStatus) => {
      if (onlineStatus) {
        if (environment?.production && error?.status !== 0) {
          Sentry.configureScope((scope) => {
            scope.setUser({
              id: sessionStorage.getItem('userData')
                ? JSON.parse(sessionStorage.getItem('userData'))['user_id']
                : 'Not Found',
              username: sessionStorage.getItem('userData')
                ? JSON.parse(sessionStorage.getItem('userData'))['username']
                : 'Not Found',
              email: sessionStorage.getItem('userData')
                ? JSON.parse(sessionStorage.getItem('userData'))['email']
                : 'Not Found',
            });
            scope.setTag('language', 'eng');
          });
          Sentry.captureException(error);
        }
        // Network outage error - obviously not our problem don't over report this as a real issue
        // if (error?.status === 0) {
        //   errorMessage = 'Error: Network Connection issue, please refresh and retry.';
        // }
         if (error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error?.error.message}`;
        } else if (error instanceof HttpErrorResponse) {
          // server-side error
          errorMessage = `Error Code: ${error?.status}\nMessage: ${error?.message}`;
          // AWS Error
        } else if (error?.code) {
          errorMessage = `Error Code: ${error?.code}\nMessage: ${error?.message}`;
        }
        if (!environment.production && error?.status !== 0) {
          log.error('Request error', error);
        }
        if (!errorMessage) {
          errorMessage = error;
        }
        if (!this.materialSnackbarService.checkForOpenSnackbar()) {
          this.materialSnackbarService.openSnackBar(errorMessage, 'dismiss');
        }
        console.error(error.stack?.toString());
      }
    });
  }
}
