import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthUserService } from '../authentication/auth-user-service';
import { Observable } from 'rxjs';
import { BaseModel } from '@app/models/base-model';
import { AddParams, ApiParamsModel } from 'app/models/api-params.model';
import { ApiFilterModel } from 'app/models/api-filter.model';
import { ApiSortModel } from 'app/models/api-sort.model';
import { Logger } from '../logger/logger.service';
import { HttpCacheService } from '../http/http-cache.service';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseModel {
  // TEST WITH APPS_DETAILS AS JOINS REQUIRE FOREIGN KEY CONSTRAINTS

  private _protocol: string = environment.serverProtocol;
  private _address: string = environment.testing
    ? environment.testApiUrl
    : `${environment.secureServerUrl}`;
  public log = new Logger('ApiService');
  constructor(
    protected http: HttpClient,
    protected authUserService: AuthUserService,
    private apiCacheService: HttpCacheService
  ) {
    super();
  }

  get baseUrl(): string {
    return `${this._protocol}://${this._address}`;
  }

  setOptions(endpoint: string, oldData?: any): Object {
    let newHeaders: HttpHeaders | Headers;
    if (environment.testing) {
      let newHeaders = new Headers();
      newHeaders.append('Content-Type', 'application/json');
      newHeaders.append('Prefer', 'return=representation');
      newHeaders.append('Accept', 'application/json');
      newHeaders.append('endpoint', endpoint);
      if (oldData) {
        newHeaders.append('data', oldData);
      }
      newHeaders.append('Authorization', this.authUserService.credentials.idToken);
    } else {
      if (!oldData) {
        newHeaders = new HttpHeaders({ endpoint: endpoint });
      } else {
        newHeaders = new HttpHeaders({
          data: oldData,
          endpoint: endpoint,
        });
      }
      if (this.authUserService.credentials && this.authUserService.credentials.idToken) {
        newHeaders = newHeaders
          .append('Authorization', this.authUserService.credentials.idToken)
          .append('clientId', environment.cognitoSettings.clientId);
      }
    }
    // Updated to user HttpHeaders as old config was not pulling through correctly
    return {
      headers: newHeaders,
      withCredentials: true,
    };
  }

  /**
   *  Warren Dowey
   *
   *  processQuery
   *
   *  process any API calls with a postgrest query, by ingesting the input and querying the API in the URL
   *
   * @param filter of type ApiFilterModel containing {field: string; operator: string; value: any;}
   * @param select of type array of strings of how to select the data
   * @param order of type ApiSortModel containing {filed: string; direction: string}
   * @param limit number to limit to
   * @param add
   * @todo TODO: needs a lot of working out around && and ||s
   *
   * @returns querystring of type string; after the query has been processed
   */
  protected processQuery(
    filter?: ApiFilterModel[],
    select?: string[],
    order?: ApiSortModel[],
    limit?: number[],
    add?: AddParams
  ) {
    let queryString = '';
    const filterArray = [];
    const orderArray = [];
    const limitArray = [];
    const addArray = [];
    let appendSymbol = '?';
    if (filter !== undefined) {
      for (const f of filter) {
        if (f.joinOperator) {
          let x = 0;
          let tempFilter = f.joinOperator.toLowerCase() + '=(';
          for (const operator of f.operator) {
            if (x > 0) {
              tempFilter += ',';
            }
            switch (operator) {
              case 'eq':
              case 'gte':
              case 'gt':
              case 'lt':
              case 'lte':
              case 'neq':
              case 'is':
              case 'like':
              case 'ilike':
                tempFilter += f.field[x] + '.' + operator + '.' + f.value[x];
                break;
              case 'in':
                tempFilter += f.field[x] + '.' + operator + '.(' + f.value[x] + ')';
                break;
              case 'fts':
                tempFilter += f.field[x] + '.' + operator + '.' + f.value[x];
                break;
              // TODO: work out how these work
              case '@@':
                tempFilter += f.field[x] + '.' + operator + '.' + f.value[x];
                break;
              // TODO: work out how these work
              case '@>':
                tempFilter += f.field[x] + '.' + operator + '.{' + f.value[x] + '}';
                break;
              // TODO: work out how these work
              case '<@':
                tempFilter += f.field[x] + '.' + operator + '{' + f.value[x] + '}';
                break;

              default:
                this.log.debug('filter added but no matching operator');
            }
            x++;
          }
          tempFilter += ')';
          filterArray.push(tempFilter);
        } else {
          switch (f.operator) {
            case 'eq':
            case 'gte':
            case 'gt':
            case 'lt':
            case 'lte':
            case 'neq':
            case 'is':
            case 'like':
            case 'ilike':
              filterArray.push(f.field + '=' + f.operator + '.' + f.value);
              break;
            case 'in':
              filterArray.push(f.field + '=' + f.operator + '.(' + f.value + ')');
              break;
            // TODO: work out how these work
            case '@@':
              filterArray.push(f.field + '=' + f.operator + '.' + f.value);
              break;
            // TODO: work out how these work
            case '@>':
              filterArray.push(f.field + '=' + f.operator + '.{' + f.value + '}');
              break;
            // TODO: work out how these work
            case '<@':
              filterArray.push(f.field + '=' + f.operator + '{' + f.value + '}');
              break;
            default:
              this.log.debug('filter added but no matching operator');
          }
        }
      }
    }
    if (order !== undefined) {
      for (const o of order) {
        orderArray.push(o.field + '.' + o.direction);
      }
    }

    if (limit) {
      for (const l of limit) {
        limitArray.push(l);
      }
    }

    if (filterArray.length) {
      queryString = queryString + appendSymbol + filterArray.join('&');
      appendSymbol = '&';
    }

    if (orderArray.length) {
      queryString = queryString + appendSymbol + 'order=' + orderArray.join(',');
      appendSymbol = '&';
    }

    if (limitArray.length) {
      queryString = queryString + appendSymbol + 'limit=' + limitArray.join(',');
    }

    if (select !== undefined && select.length) {
      queryString = queryString + appendSymbol + 'select=' + select.join(',');
    }

    if (add !== undefined) {
      queryString = queryString + appendSymbol + add.field + '=eq.' + add.value;
    }
    return queryString;
  }

  /**
   *
   * @param endpoint
   * @param request this can either be the raw string or the APIParam Model
   * For example: 'app_slug=eq.boxplots_shiny' is the raw string for getting the app with the boxplots slug
   * OR
   * const apiParams = new ApiParamsModel();
   const apiFilter = new ApiFilterModel();
   apiFilter.field = 'app_slug';
   apiFilter.operator = 'eq';
   apiFilter.value = 'boxplots_shiny';
   apiParams.filter = [apiFilter];  provide this apiParamsModel will return the exact same result.
   This is preference and for more complex queries the raw string will come majorly in handy.
   *
   *
   * @returns void
   */
  getEndpoint<responseType>(
    endpoint: string,
    request?: string | ApiParamsModel
  ): Observable<responseType[]> {
    let result: responseType[];
    let query: string;
    let newModel: responseType;
    if (request) {
      query =
        request instanceof ApiParamsModel
          ? this.processQuery(
              request.filter,
              request.select,
              request.sort,
              request.limit,
              request.add
            )
          : `?${request}`;
    }
    const requestEndpoint = request
      ? `${this.baseUrl}/${endpoint}${query}`
      : `${this.baseUrl}/${endpoint}`;
    return new Observable<responseType[]>((observer) => {
      this.http.get(requestEndpoint, this.setOptions(endpoint)).subscribe(
        (data: Object[]) => {
          result = [];
          for (const item of data) {
            newModel = {} as responseType;
            result.push(super.fromJson<responseType>(item, newModel));
          }
          observer.next(result);
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /**
   *
   * @param endpoint
   * @param request
   * @param data
   * @param uuidColumn1
   * @param uuidColumn2
   * @returns
   */
  deleteEndpoint<T>(
    endpoint: string,
    request: ApiParamsModel,
    data: T,
    uuidColumn1: string,
    uuidColumn2?: string
  ): Observable<string> {
    const query: string = this.processQuery(
      request.filter,
      request.select,
      request.sort,
      request.limit,
      request.add
    );
    const requestEndpoint = `${this.baseUrl}/${endpoint}${query}`;
    this.apiCacheService.updateCacheItem(
      endpoint,
      'delete',
      super.toJson(data),
      uuidColumn1,
      uuidColumn2
    );
    return new Observable<string>((observer) => {
      this.http
        .get(requestEndpoint, this.setOptions(endpoint))
        .pipe(
          switchMap((data: Object[]) => {
            return this.http.delete(requestEndpoint, this.setOptions(endpoint, data));
          })
        )
        .subscribe((data: Object[]) => {
          observer.next('Successfully deleted data');
          observer.complete();
        });
    });
  }

  /**
   *
   * @param endpoint
   * @param request
   * @param data
   * @param idColumn1
   * @param idColumn2
   * @param updateMade
   * @returns
   */
  patchEndpoint<dataType>(
    endpoint: string,
    request?: string | ApiParamsModel,
    data?: dataType | dataType[],
    idColumn1?: string,
    idColumn2?: string,
    updateMade?: Object[]
  ): Observable<string> {
    let query: string;
    if (request) {
      query =
        request instanceof ApiParamsModel
          ? this.processQuery(
              request.filter,
              request.select,
              request.sort,
              request.limit,
              request.add
            )
          : `?${request}`;
    }
    const updateBody: Object[] = [];
    let apiUpdate: Object[];
    if (!Array.isArray(data)) {
      updateBody.push(super.toJson<dataType>(data));
    } else {
      for (const item of data) {
        updateBody.push(super.toJson<dataType>(item));
      }
    }
    const requestEndpoint = `${this.baseUrl}/${endpoint}${query}`;
    for (const update of updateBody) {
      this.apiCacheService.updateCacheItem(endpoint, 'patch', update, idColumn1, idColumn2);
    }
    apiUpdate = updateMade ? updateMade : updateBody;
    return new Observable<string>((observer) => {
      this.http
        .get(requestEndpoint, this.setOptions(endpoint))
        .pipe(
          switchMap((data) => {
            return this.http.patch(requestEndpoint, apiUpdate, this.setOptions(endpoint, data));
          })
        )
        .subscribe(() => {
          observer.next('Successfully updated data');
          observer.complete();
        });
    });
  }
  /**
   *
   * @param endpoint
   * @param data
   * @param isJson
   * @returns
   */
  postEndpoint<dataType>(
    endpoint: string,
    data: dataType | dataType[] | Object[] | Object,
    isJson?: boolean
  ) {
    return new Observable<string>((observer) => {
      const updateBody = [];
      if (!Array.isArray(data)) {
        if (!isJson) {
          updateBody.push(super.toJson<dataType | Object>(data));
        } else {
          updateBody.push(data);
        }
      } else {
        for (const item of data) {
          if (!isJson) {
            updateBody.push(super.toJson<dataType | Object>(item));
          } else {
            updateBody.push(item);
          }
        }
      }
      const requestEndpoint = `${this.baseUrl}/${endpoint}`;
      for (const update of updateBody) {
        this.apiCacheService.updateCacheItem(endpoint, 'add', update);
      }
      this.http.post(requestEndpoint, updateBody, this.setOptions(endpoint)).subscribe(() => {
        observer.next('Successfully inserted data');
        observer.complete();
      });
    });
  }
}
