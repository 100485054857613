<div class="login-container">
  <div class="modal-content">
    <div class="modal-body">
      <mat-icon class="wifi-icon">wifi_off</mat-icon>
      <h4 class="text-center">Network Connection Lost</h4>
      <h6>
        Please do not refresh this page as <b>work will be lost.</b>. Your session will be
        <b>automatically continued </b> when your connection is back online.
      </h6>
    </div>
  </div>
</div>
