export interface Change {
  type: string;
  rowDetails: Object;
  updatedData?: Object;
}

export class DataChangesModel {
  private _fileName: string;
  private _changes: Change[];

  constructor(fileName?: string, changes?: Change[]) {
    this._fileName = fileName;
    this._changes = changes;
  }

  public get fileName(): string {
    return this._fileName;
  }

  public set fileName(fileName: string) {
    this._fileName = fileName;
  }

  public get changes(): Change[] {
    return this._changes;
  }

  public set changes(changes: Change[]) {
    this._changes = changes;
  }

  toJson(): Object {
    const object = {
      file_name: this.fileName,
      changes: this.changes,
    };
    Object.keys(object).forEach(
      (k) =>
        !object[k] &&
        typeof object[k] !== 'boolean' &&
        typeof object[k] !== 'number' &&
        object[k] !== undefined &&
        delete object[k]
    );
    return object;
  }

  fromJson(input: any): DataChangesModel {
    if (input) {
      return new DataChangesModel(input['file_name'], input['changes']);
    } else {
      return new DataChangesModel();
    }
  }
}
